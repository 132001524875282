import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { PaymentForm } from "../../../components/PaymentForm";
import usePaymentForm from "../../../components/PaymentForm/usePaymentForm";
import api from "../../../services/Api";

export type ModalRetryCardProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  companySubscriptionPlanPaymentId: number;
  onSubmitted?: () => void;
  onClose?: () => void;
  value: number;
};

export function ModalRetryCard({
  showModal,
  setShowModal,
  companySubscriptionPlanPaymentId,
  onSubmitted,
  onClose,
  value,
}: ModalRetryCardProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeCardInfo, setChangeCardInfo] = useState(false);
  const [installments, setInstallments] = useState(1);

  const {
    cardNumber,
    setCardNumber,
    expiry,
    setExpiry,
    cvc,
    setCvc,
    name,
    setName,
    cardNumberError,
    setCardNumberError,
    nameError,
    setNameError,
    expiryError,
    setExpiryError,
    cvcError,
    setCvcError,
  } = usePaymentForm();

  function handleCloseModal() {
    setShowModal(false);

    if (onClose) {
      onClose();
    }
  }

  function inputsVerify() {
    if (!changeCardInfo) {
      return true;
    }

    if (nameError) {
      alert("Preencha o nome do titular do cartão!");
      return false;
    }
    if (cardNumberError) {
      alert("Preencha corretamente o número do cartão!");
      return false;
    }
    if (expiryError) {
      alert("Preencha a data de validade do cartão!");
      return false;
    }
    if (cvcError) {
      alert("Preencha o código de segurança do cartão!");
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    setIsSubmitting(true);

    const isValid = inputsVerify();

    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    try {
      // Vincular plano
      const raw = {
        changeCardInfo,
        installments,
        card: {
          name,
          cardNumber,
          expiry,
          cvc,
        },
      };

      await api.patch(
        `companySubscriptionPlanPayment/card/${companySubscriptionPlanPaymentId}`,
        raw
      );

      setShowModal(false);
      setChangeCardInfo(false);
      setCardNumber("");
      setExpiry("");
      setCvc("");
      setName("");
    } catch (error) {
      console.log("error", error);
      alert("Ocorreu um erro ao processar a requisição");
    }

    if (onSubmitted) {
      onSubmitted();
    }

    setIsSubmitting(false);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-warning"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          Processar Cartão de Crédito
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col bg-warning-o-30 p-5 mb-3">
            <h4>
              A instituição não autorizou o cartão de crédito informado para o
              seu plano. Você pode tentar processar o pagamento novamente ou
              alterar os dados do seu cartão de crédito.
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => setChangeCardInfo(e.target.checked)}
                  checked={changeCardInfo}
                />
              }
              label="Alterar dados do cartão"
              labelPlacement="end"
              className="ml-1"
            />
          </div>
        </div>

        {changeCardInfo && (
          <div className="row">
            <div className="col text-center">
              <h4>Preencha as informações do cartão de crédito</h4>

              <PaymentForm
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                expiry={expiry}
                setExpiry={setExpiry}
                cvc={cvc}
                setCvc={setCvc}
                name={name}
                setName={setName}
                cardNumberError={cardNumberError}
                setCardNumberError={setCardNumberError}
                expiryError={expiryError}
                setExpiryError={setExpiryError}
                cvcError={cvcError}
                setCvcError={setCvcError}
                nameError={nameError}
                setNameError={setNameError}
                installments={installments}
                setInstallments={setInstallments}
                value={value}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Fechar
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
        >
          {isSubmitting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="ml-2">Aguarde...</span>
            </>
          ) : (
            <>
              <span>Enviar</span>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
