import { SellerConsignment, SellerConsignmentProduct } from "./SellerConsignment";
import { typePeople } from "./typePeople";
import { yesOrNo } from "./yesOrNo";

export enum situation {
    ACTIVE = "active",
    INACTIVE = "inactive"
}

export type Seller = {
    id: number;
    companyId: number;
    typePeople: typePeople;
    cpf: string;
    name: string;
    cnpj: string;
    corporateName: string;
    fantasyName: string;
    zipCode: string;
    address: string;
    number: string;
    district: string;
    complement: string;
    city: string;
    state: string;
    contactPeople: string;
    phone: string;
    extension: string;
    fax: string;
    cell: string;
    email: string;
    webSite: string;
    bank: string;
    agency: string;
    currentAccount: string;
    salary: string;
    sellerCommission: string;
    userId: number;
    isConsignment: yesOrNo;
    situation: situation;
    note: string;

    openConsignments: SellerConsignment[];
    availableConsignmentProducts: SellerConsignmentProduct[];
}
