import React from "react";
import { Checkbox, TextField, FormControlLabel } from "@material-ui/core";
import { useStyles } from "../../../hooks/styles";
import { formatToFloat } from "../../../utils/formatCurrency";
import { Button } from "react-bootstrap";
import { Installment } from "../Installment";

interface PaymentTabProps {
  sellerCommissioning: boolean;
  setSellerCommissioning: (value: boolean) => void;
  installmentAmount: number;
  setInstallmentAmount: (value: number) => void;
  installmentError: boolean;
  dispatchInstallments: (action: any) => void;
  totalValue: string;
  installments: any[];
}

const PaymentTab: React.FC<PaymentTabProps> = ({
  sellerCommissioning,
  setSellerCommissioning,
  installmentAmount,
  setInstallmentAmount,
  installmentError,
  dispatchInstallments,
  totalValue,
  installments,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={sellerCommissioning}
                onChange={(e, checked) => setSellerCommissioning(checked)}
                className={classes.textField}
              />
            }
            label="Emitir comissionamento (vendedor)"
            labelPlacement="end"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <TextField
            size="small"
            type="number"
            margin="normal"
            variant="outlined"
            label="Quantidade de Parcelas*"
            value={installmentAmount}
            onChange={(evt) => setInstallmentAmount(Number(evt.target.value))}
            error={installmentError}
          />
        </div>

        <div className="col-md-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            size="sm"
            block
            onClick={() =>
              dispatchInstallments({
                type: "GENERATE",
                payload: {
                  amount: installmentAmount,
                  value: formatToFloat(totalValue),
                },
              })
            }
          >
            Gerar parcelas
          </Button>
        </div>

        <div className="row col-lg-12 mt-10">
          {installments.length > 0 &&
            installments.map((installment, index) => (
              <Installment
                key={index}
                index={index}
                installment={installment}
                installmentAmount={installmentAmount}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default PaymentTab;
