import React, { useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";

interface ButtonProps {
  buttons: {
    label: string;
    icon: string;
    onClick: () => void;
  }[];
}

const useStyles = makeStyles({
  button: {
    border: "2px solid transparent",
    "&:hover": {
      border: "2px solid #ccc",
    },
  },
  activeButton: {
    border: "2px solid gold",
  },
});

const PlanButtons: React.FC<ButtonProps> = ({ buttons }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleButtonClick = (index: number, onClick: () => void) => {
    setActiveIndex(index);
    onClick();
  };

  return (
    <Grid container spacing={2}>
      {buttons.map(({ label, icon, onClick }, index) => (
        <Grid item xs={4} key={`${label}-${index}`}>
          <Button
            variant="contained"
            // color="default"
            style={{ backgroundColor: "#FFF", height: "60px" }}
            startIcon={<i className={icon} style={{ color: "#000" }} />}
            onClick={() => handleButtonClick(index, onClick)}
            className={`${classes.button} ${
              activeIndex === index ? classes.activeButton : ""
            }`}
            fullWidth
          >
            {label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanButtons;
