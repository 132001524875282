import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import "../../ProgressBarForm.css";
import { SubscriptionPlanSelector } from "../SubscriptionPlanSelector";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { PermissionMenu } from "../../types/PermissionMenu";
import useMenu from "../../hooks/menu";
import { Profile } from "../../types/Profile";
import { getDate } from "../../utils/dateTimeHelper";
import SubscriptionPlanService from "../../services/SubscriptionPlanService";
import ModalError from "../ModalError";
import api from "../../services/Api";
import { bypassAuthHeader } from "../../utils/bypassAuthHeader";
import { GenericObject } from "../../types/GenericObject";
import CompanyService from "../../services/CompanyService";
import usePaymentForm from "../PaymentForm/usePaymentForm";
import { PaymentForm } from "../PaymentForm";
import { yesOrNo } from "../../types/yesOrNo";
import { CompanySubscriptionPlan } from "../../types/CompanySubscriptionPlan";
import { useCompanyBranch } from "../../hooks/companyBranch";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import PlanButtons from "./Components/buttons";
import { useWebSocket } from "../../hooks/useWebSocket";

export type ExecuteCreateCompanyPlanFunction = (
  createdCompanyId?: number
) => Promise<CompanySubscriptionPlan>;

export type Step = "selectPlan" | "fillPayment";
type PaymentMethod = "credit_card" | "billet" | "pix" | undefined;

type SubscriptionPlanCheckoutProps = {
  onSubmit: (
    executeCreateCompanyPlan: ExecuteCreateCompanyPlanFunction,
    subscriptionPlan: SubscriptionPlan,
    expirationDate: string,
    permissions: number[]
  ) => any;
  onClickNextStep?: (
    currentStep: Step,
    selectedSubscriptionPlan: SubscriptionPlan | undefined,
    toNextStep: () => void
  ) => any;
  companyId: number;
  additionalFilters?: GenericObject;
  title?: string;
  selectFirstSubscriptionPlan?: boolean;
  companyPlan?: any;
};

const firstStep = "selectPlan";
const lastStep = "fillPayment";

export function SubscriptionPlanCheckout({
  onSubmit,
  onClickNextStep,
  companyId,
  additionalFilters,
  title = "Selecione uma das opções de plano abaixo",
  selectFirstSubscriptionPlan,
  companyPlan,
}: SubscriptionPlanCheckoutProps) {
  const { selectedCompany, reloadCompanyData } = useCompanyBranch();
  const [activeStep, setActiveStep] = useState<Step>("selectPlan");
  const [nextStepLoading, setNextStepLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<Step[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [company, setCompany] = useState<any>();
  const [loadingPix, setLoadingPix] = useState(false);

  const { user } = useSelector((state: any) => state.auth);

  //step 1 states
  const { menus } = useMenu();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    SubscriptionPlan
  >();
  const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(
    menus as PermissionMenu[]
  );
  const [profileId, setProfileId] = useState(0);
  const [expirationDate, setExpirationDate] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  //step 2 states
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [installments, setInstallments] = useState(1);
  const [pixData, setPixData] = useState<CompanySubscriptionPlan>();

  const {
    cardNumber,
    setCardNumber,
    expiry,
    setExpiry,
    cvc,
    setCvc,
    name,
    setName,
    cardNumberError,
    setCardNumberError,
    nameError,
    setNameError,
    expiryError,
    setExpiryError,
    cvcError,
    setCvcError,
  } = usePaymentForm();

  useEffect(() => {
    setPermissionMenus(menus as PermissionMenu[]);
  }, [menus]);

  useEffect(() => {
    async function loadCompany() {
      if (!companyId) {
        return;
      }

      const company = await CompanyService.getCompanyById(companyId);

      setProfileId(company.profileId);
      setCompany(company);
    }

    loadCompany();
  }, []);

  const [qrCode, setQrCode] = useState({
    canShow: false,
    qr_code: "",
    qr_code_url: "",
  });

  async function handleSubmit() {
    setIsSubmitting(true);

    const isValid = inputsVerify();

    if (!isValid) {
      setIsSubmitting(false);
      setShowModalError(true);
      return;
    }

    var cnpjOrCpfIsValid = true;
    if (selectedCompany.typePeople == "legal") {
      const cnpjFormated = selectedCompany.cnpj.replace(/[^0-9]/g, "");
      cnpjOrCpfIsValid = cnpj.isValid(cnpjFormated);
    } else {
      const cpfFormated = selectedCompany.cpf.replace(/[^0-9]/g, "");
      cnpjOrCpfIsValid = cpf.isValid(cpfFormated);
    }

    if (!cnpjOrCpfIsValid) {
      setErrorMsg(
        `${
          selectedCompany.typePeople == "legal" ? "CNPJ" : "CPF"
        } da sua empresa é inválido para o pagamento com boleto, para utilizar deste meio de pagamento é preciso informar um ${
          selectedCompany.typePeople == "legal" ? "CNPJ" : "CPF"
        } válido em DADOS DA EMPRESA. Dúvidas entrar em contato com o suporte`
      );
      setIsSubmitting(false);
      setShowModalError(true);
      return;
    }

    const aux = permissionMenus.map((menu) => ({ ...menu }));

    const menusFiltered = filterPermissionMenu(aux);
    const permissions = mapPermissionMenu(menusFiltered);

    try {
      // Vincular plano
      const rawPlan: GenericObject = {
        companyId: companyId,
        planId: selectedSubscriptionPlan!.id,
        expirationDate: expirationDate || null,
        alertMsg: null,
        value: selectedSubscriptionPlan!.value,
        paymentDate: new Date(),
        paymentDetails: {
          installments,
          method: paymentMethod,
          card:
            paymentMethod === "credit_card"
              ? {
                  name,
                  cardNumber,
                  expiry,
                  cvc,
                }
              : undefined,
        },
        requireToSubscribe:
          selectedSubscriptionPlan!.value <= 0 ? yesOrNo.NO : yesOrNo.YES,
      };

      const executeCreateCompanyPlan = async (createdCompanyId?: number) => {
        rawPlan.companyId = createdCompanyId ?? rawPlan.companyId;
        const createdCompanyPlan = await api.post<CompanySubscriptionPlan>(
          `companySubscriptionPlans`,
          rawPlan,
          { headers: bypassAuthHeader }
        );
        return createdCompanyPlan.data;
      };

      await onSubmit(
        paymentMethod === "pix" && pixData
          ? async () => pixData
          : executeCreateCompanyPlan,
        selectedSubscriptionPlan!,
        expirationDate,
        permissions
      );
    } catch (error) {
      console.log("error", error);
      setErrorMsg("Ocorreu um erro ao processar a requisição");
      setShowModalError(true);
    }

    setIsSubmitting(false);
  }
  async function handlePix() {
    setLoadingPix(false);
    setIsSubmitting(true);

    var cnpjOrCpfIsValid = true;
    if (selectedCompany.typePeople == "legal") {
      const cnpjFormated = selectedCompany.cnpj.replace(/[^0-9]/g, "");
      cnpjOrCpfIsValid = cnpj.isValid(cnpjFormated);
    } else {
      const cpfFormated = selectedCompany.cpf.replace(/[^0-9]/g, "");
      cnpjOrCpfIsValid = cpf.isValid(cpfFormated);
    }

    if (!cnpjOrCpfIsValid) {
      setErrorMsg(
        `${
          selectedCompany.typePeople == "legal" ? "CNPJ" : "CPF"
        } da sua empresa é inválido para o pagamento com boleto, para utilizar deste meio de pagamento é preciso informar um ${
          selectedCompany.typePeople == "legal" ? "CNPJ" : "CPF"
        } válido em DADOS DA EMPRESA. Dúvidas entrar em contato com o suporte`
      );
      setIsSubmitting(false);
      setShowModalError(true);
      return;
    }

    try {
      const rawPlan: GenericObject = {
        companyId: companyId,
        planId: selectedSubscriptionPlan!.id,
        expirationDate: expirationDate || null,
        alertMsg: null,
        value: selectedSubscriptionPlan!.value,
        // paymentDate: new Date(),
        paymentDetails: {
          installments,
          method: "pix",
          card: undefined,
        },
        requireToSubscribe:
          selectedSubscriptionPlan!.value <= 0 ? yesOrNo.NO : yesOrNo.YES,
      };

      const executeCreateCompanyPlan = async (createdCompanyId?: number) => {
        rawPlan.companyId = createdCompanyId ?? rawPlan.companyId;
        const createdCompanyPlan = await api.post<CompanySubscriptionPlan>(
          `companySubscriptionPlans`,
          rawPlan,
          { headers: bypassAuthHeader }
        );
        return createdCompanyPlan.data;
      };

      const planData = await executeCreateCompanyPlan();
      setPixData(planData);

      const { paymentData } = planData;

      const {
        qr_code,
        qr_code_url,
      } = paymentData?.charges[0]?.last_transaction;

      setQrCode({ canShow: true, qr_code, qr_code_url });
      setLoadingPix(true);
      setIsSubmitting(false);
    } catch (error) {
      console.log("error", error);
      setErrorMsg("Ocorreu um erro ao processar a requisição");
      setShowModalError(true);
    }

    setIsSubmitting(false);
  }

  const { invoice } = useWebSocket();

  useEffect(() => {
    if (invoice.invoiceId === pixData?.paymentData?.id) {
      if (invoice.status === "paid") {
        const aux = permissionMenus.map((menu) => ({ ...menu }));
        const menusFiltered = filterPermissionMenu(aux);
        const permissions = mapPermissionMenu(menusFiltered);
        onSubmit(
          async () => pixData,
          selectedSubscriptionPlan!,
          expirationDate,
          permissions
        );
      }
    }
  }, [invoice]);

  function inputsVerify() {
    if (!paymentMethod) {
      setErrorMsg("Selecione uma forma de pagamento!");
      return false;
    }

    // Validação para caso a empresa não tiver preenchido o endereço (não vai ocorrer com empresas novas)
    if (
      company &&
      !company.zipCode &&
      !company.address &&
      !company.number &&
      !company.district &&
      !company.city &&
      !company.state
    ) {
      setErrorMsg(
        "Endereço não foi informado no cadastro da empresa, entre em contato com o suporte do sistema para obter assistência."
      );
      return false;
    }

    if (paymentMethod === "credit_card") {
      if (nameError) {
        setErrorMsg("Preencha o nome do titular do cartão!");
        return false;
      }
      if (cardNumberError) {
        setErrorMsg("Preencha corretamente o número do cartão!");
        return false;
      }
      if (expiryError) {
        setErrorMsg("Preencha a data de validade do cartão!");
        return false;
      }
      if (cvcError) {
        setErrorMsg("Preencha o código de segurança do cartão!");
        return false;
      }
    }

    return true;
  }

  function handleClickStep(step: Step) {
    // Verificar se a prox etapa pode ser selecionada
    if (step === "fillPayment" && !completedSteps.includes("selectPlan")) {
      return;
    }

    setActiveStep(step);
  }

  function handleClickNextStep() {
    // Validando
    if (activeStep === "selectPlan") {
      if (!selectedSubscriptionPlan) {
        setErrorMsg("É obrigatório selecionar o plano!");
        setShowModalError(true);
        return;
      }
    }

    const toNextStep = () => {
      const auxCompletedSteps = completedSteps;

      // Completar etapa
      if (!auxCompletedSteps.includes(activeStep)) {
        auxCompletedSteps.push(activeStep);
      }

      setCompletedSteps([...auxCompletedSteps]);

      if (activeStep === "selectPlan") {
        handleClickStep("fillPayment");
      }
    };

    if (onClickNextStep) {
      onClickNextStep(activeStep, selectedSubscriptionPlan, toNextStep);
    } else {
      toNextStep();
    }
  }

  function handleClickPreviousStep() {
    if (activeStep === "fillPayment") {
      handleClickStep("selectPlan");
      setCompletedSteps([]);
    }
  }

  function stepIsActive(step: Step) {
    return activeStep === step ? "active" : "";
  }

  function stepIsComplete(step: Step) {
    return completedSteps.includes(step) ? "completed" : "";
  }

  // Step 1 methods
  useEffect(() => {
    async function getProfiles() {
      const { data } = await api.get<Profile[]>("profiles", {
        headers: bypassAuthHeader,
      });
      setProfiles(data);
    }

    getProfiles();
  }, []);

  function handleChangeProfile(profile: Profile | null) {
    setProfileId(profile?.id ?? 0);
    if (profile) {
      const aux = permissionMenus.map((menu) => ({ ...menu }));
      const permissions = mapPermissionsChecked(
        JSON.parse(profile.permissions),
        aux
      );
      setPermissionMenus([...permissions]);
    }
  }

  function handleChangeSubscriptionPlan(
    subscriptionPlan: SubscriptionPlan | null
  ) {
    if (subscriptionPlan) {
      const planExpirationDateObj = SubscriptionPlanService.addPlanDurationToTodayDate(
        subscriptionPlan
      );
      const planExpirationDateString = planExpirationDateObj
        ? getDate({ initialDate: planExpirationDateObj, dateFormat: "y-m-d" })
            .dateStr
        : "";

      setExpirationDate(planExpirationDateString);
    }

    const profileObject: Profile | null =
      profiles.find((profile) => profile.id == subscriptionPlan?.profileId) ??
      null;

    setSelectedSubscriptionPlan(subscriptionPlan ?? undefined);
    handleChangeProfile(profileObject);
  }

  function filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
    const filtered = menus.filter((menu) => menu.checked);
    if (!filtered || filtered.length === 0) {
      return [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].submenus?.length) {
        filtered[i].submenus = filterPermissionMenu(
          filtered[i].submenus as PermissionMenu[]
        ).filter(() => true);
      }
    }
    return filtered;
  }

  function mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
    let mapped = menus.map((menu) => menu.id);
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].submenus?.length) {
        mapped = mapped.concat(
          mapPermissionMenu(menus[i].submenus as PermissionMenu[])
        );
      }
    }
    return mapped;
  }

  function mapPermissionsChecked(
    permissions: Array<number>,
    menus: PermissionMenu[]
  ) {
    const aux = menus;
    for (let i = 0; i < aux.length; i++) {
      aux[i].checked = permissions.includes(aux[i].id);
      if (aux[i].submenus?.length) {
        aux[i].submenus = mapPermissionsChecked(
          permissions,
          aux[i].submenus as PermissionMenu[]
        );
      }
    }
    return aux;
  }

  useEffect(() => {
    const profile = profiles.find((profile) => profile.id == profileId);

    if (profile?.name?.toLowerCase().includes("gratuito")) {
      setPaymentMethod("credit_card");
      setIsFreeTrial(true);
    }
  }, [profiles, profileId]);

  //step 2 methods

  const allButtons = [
    {
      label: "PIX",
      icon: "fas fa-qrcode",
      onClick: () => {
        setPaymentMethod("pix");
        handlePix();
      },
    },
    {
      label: "Cartão",
      icon: "fas fa-credit-card",
      onClick: () => setPaymentMethod("credit_card"),
    },
    {
      label: "Boleto",
      icon: "fas fa-file-invoice-dollar",
      onClick: () => setPaymentMethod("billet"),
    },
  ];

  const buttonsFirstPayment = [allButtons[0], allButtons[1]];

  const getPixWrapper = () => {
    return (
      <div className="row">
        <div className="col text-center">
          <h4>Você está pagando com PIX</h4>
          <div>
            {qrCode.canShow && (
              <div>
                <QRCodeCanvas value={qrCode.qr_code} size={256} />
                <p
                  style={{
                    marginTop: "20px",
                    wordWrap: "break-word",
                  }}
                >
                  <strong>Pix Copia e Cola:</strong> <br />
                  {qrCode.qr_code}
                </p>
              </div>
            )}
          </div>
          <footer>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="ml-2">Aguardando pagamento...</span>
          </footer>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <ModalError
        msgError={errorMsg}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <div className="stepper-wrapper">
        <div
          className={`stepper-item ${stepIsActive(
            "selectPlan"
          )} ${stepIsComplete("selectPlan")}`}
        >
          <div
            className="step-counter"
            onClick={(e) => handleClickStep("selectPlan")}
          >
            1
          </div>
          <div className="step-name">Selecione um plano</div>
        </div>

        <div
          className={`stepper-item ${stepIsActive(
            "fillPayment"
          )} ${stepIsComplete("fillPayment")}`}
        >
          <div
            className="step-counter"
            onClick={(e) => handleClickStep("fillPayment")}
          >
            2
          </div>
          <div className="step-name">Pagamento</div>
        </div>
      </div>

      <div id="contentTabs">
        {/* selectPlan */}
        {activeStep === "selectPlan" && (
          <div className="stepper-content">
            <div className="row">
              <div className="col text-center">
                <h5>{title}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SubscriptionPlanSelector
                  selectedSubscriptionPlan={selectedSubscriptionPlan}
                  handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
                  additionalFilters={additionalFilters}
                  bypassAuth={true}
                  selectFirstSubscriptionPlan={selectFirstSubscriptionPlan}
                  showNotVisiblePlans={user?.isSuper === "y"}
                  companyPlan={companyPlan}
                />
              </div>
            </div>
          </div>
        )}

        {/* fillPayment */}
        {activeStep === "fillPayment" && (
          <div className="stepper-content">
            {!!selectedSubscriptionPlan &&
              selectedSubscriptionPlan.value > 0 &&
              !isFreeTrial && (
                <>
                  <div className="row">
                    <div className="col text-center">
                      <h5>Selecione uma forma de pagamento</h5>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col">
                      <PlanButtons buttons={allButtons} />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {paymentMethod === "pix" && <>{getPixWrapper()}</>}
                      {paymentMethod === "credit_card" && (
                        <>
                          <div className="row">
                            <div className="col text-center">
                              <h4>Preencha as informações de pagamento</h4>

                              <PaymentForm
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                expiry={expiry}
                                setExpiry={setExpiry}
                                cvc={cvc}
                                setCvc={setCvc}
                                name={name}
                                setName={setName}
                                cardNumberError={cardNumberError}
                                setCardNumberError={setCardNumberError}
                                expiryError={expiryError}
                                setExpiryError={setExpiryError}
                                cvcError={cvcError}
                                setCvcError={setCvcError}
                                nameError={nameError}
                                setNameError={setNameError}
                                installments={installments}
                                setInstallments={setInstallments}
                                value={selectedSubscriptionPlan?.value}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {paymentMethod === "billet" && (
                        <>
                          <div className="row">
                            <div className="col text-center">
                              <h4>
                                Para cada fatura do seu plano será gerado um
                                boleto de cobrança no qual poderá ser realizado
                                o pagamento.
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

            {!!selectedSubscriptionPlan && selectedSubscriptionPlan.value <= 0 && (
              <div className="row">
                <div className="col text-center">
                  <h4>
                    Plano gratuito, não é necessário informar uma forma de
                    pagamento.
                  </h4>
                </div>
              </div>
            )}

            {!!selectedSubscriptionPlan &&
              selectedSubscriptionPlan.value > 0 &&
              isFreeTrial && (
                <>
                  <div className="row">
                    <div className="col text-center">
                      <h5>Selecione uma forma de pagamento</h5>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col">
                      <PlanButtons buttons={buttonsFirstPayment} />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {paymentMethod === "pix" && <>{getPixWrapper()}</>}
                      {paymentMethod === "credit_card" && (
                        <>
                          <div className="row">
                            <div className="col text-center">
                              <h4>Preencha as informações de pagamento</h4>

                              <PaymentForm
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                expiry={expiry}
                                setExpiry={setExpiry}
                                cvc={cvc}
                                setCvc={setCvc}
                                name={name}
                                setName={setName}
                                cardNumberError={cardNumberError}
                                setCardNumberError={setCardNumberError}
                                expiryError={expiryError}
                                setExpiryError={setExpiryError}
                                cvcError={cvcError}
                                setCvcError={setCvcError}
                                nameError={nameError}
                                setNameError={setNameError}
                                installments={installments}
                                setInstallments={setInstallments}
                                value={selectedSubscriptionPlan?.value}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
          </div>
        )}
      </div>

      <div className="row mt-1">
        <div className="col-lg-12 d-flex align-items-start justify-content-between">
          {activeStep !== firstStep ? (
            <Button
              type="button"
              className="mt-4"
              variant="secondary"
              onClick={handleClickPreviousStep}
            >
              <span>&lt; Anterior</span>
            </Button>
          ) : (
            <div></div>
          )}

          {activeStep !== lastStep ? (
            <>
              <Button
                type="button"
                className="mt-4"
                variant="primary"
                disabled={nextStepLoading}
                onClick={() => handleClickNextStep()}
              >
                {nextStepLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <>
                    <span>Próximo &gt;</span>
                  </>
                )}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                className="mt-4"
                variant="primary"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <>
                    <span>Finalizar</span>
                  </>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
