import React from "react";
import { TextField } from "@material-ui/core";
import UploadFiles from "../../../components/UploadFiles";

interface DetailsTabProps {
  requestDate: string;
  deadLine: string;
  setDeadLine: (value: string) => void;
  handleSetDeadLine: () => void;
  reference: string;
  setReference: (value: string) => void;
  note: string;
  setNote: (value: string) => void;
  insideNote: string;
  setInsideNote: (value: string) => void;
  filesSelected: File[] | null;
  setFilesSelected: React.Dispatch<React.SetStateAction<File[] | null>>;
  filesNamesSaved: string[];
  setFilesNamesSaved: React.Dispatch<React.SetStateAction<string[]>>;
}

const DetailsTab: React.FC<DetailsTabProps> = ({
  requestDate,
  deadLine,
  setDeadLine,
  handleSetDeadLine,
  reference,
  setReference,
  note,
  setNote,
  insideNote,
  setInsideNote,
  filesSelected,
  setFilesSelected,
  filesNamesSaved,
  setFilesNamesSaved,
}) => {
  return (
    <div className="row">
      <div className="col-lg-2 d-flex align-items-center">
        <TextField
          size="small"
          type="date"
          label="Data do pedido"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          value={requestDate}
        />
      </div>

      {/* <div className="col-lg-2">
        <TextField
          size="small"
          type="date"
          margin="normal"
          variant="outlined"
          label="Prazo de Entrega"
          InputLabelProps={{
            shrink: true,
          }}
          value={deadLine}
          onBlur={handleSetDeadLine}
          onChange={(evt) => setDeadLine(evt.target.value)}
        />
      </div> */}

      <div className="col-lg-8">
        <TextField
          size="small"
          label="Referência"
          margin="normal"
          variant="outlined"
          value={reference}
          onChange={(evt) => setReference(evt.target.value)}
        />
      </div>

      <div className="col-lg-6">
        <TextField
          size="small"
          label="Observações"
          multiline
          rows="2"
          placeholder="Esta informação será impressa nas observações da nota."
          margin="normal"
          variant="outlined"
          value={note}
          onChange={(evt) => setNote(evt.target.value)}
        />
      </div>

      <div className="col-lg-6">
        <TextField
          size="small"
          multiline
          rows="2"
          margin="normal"
          variant="outlined"
          label="Observações Internas"
          placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
          value={insideNote}
          onChange={(evt) => setInsideNote(evt.target.value)}
        />
      </div>

      <div className="col-lg-6 ml-2">
        <UploadFiles
          label="Contrato/Anexos"
          filesSelected={filesSelected}
          setFilesSelected={setFilesSelected}
          filesNamesSaved={filesNamesSaved}
          setFilesNamesSaved={setFilesNamesSaved}
        />
      </div>
    </div>
  );
};

export default DetailsTab;
