import React from "react";
import { ListProducts } from "../ListProducts";
import { Product } from "../context";
import ApiResourceSelect from "../../../components/ApiResourceSelect";
import { PriceList } from "../../../types/PriceList";
import PriceListService from "../../../services/PriceListService";
import { formatToFloat } from "../../../utils/formatCurrency";

interface ProductsTabProps {
  products: Product[];
  dispatchProducts: (action: any) => void;
  handleClickAddProduct: (
    typedText: string,
    index: number,
    product: Product
  ) => void;
  selectedSellerIsAuthConsignmentSeller: boolean;
  productsAvailableForConsignment: any;
  priceList: PriceList;
  handleSetPriceList: (value: PriceList | null) => void;
  totalValueProducts: string;
}

const ProductsTab: React.FC<ProductsTabProps> = ({
  products,
  dispatchProducts,
  handleClickAddProduct,
  selectedSellerIsAuthConsignmentSeller,
  productsAvailableForConsignment,
  priceList,
  handleSetPriceList,
  totalValueProducts,
}) => {
  return (
    <div className="row">
      {products.map((prod, index) => (
        <ListProducts
          key={index}
          product={prod}
          index={index}
          onClickAddProduct={handleClickAddProduct}
          sellerConsignmentProducts={
            selectedSellerIsAuthConsignmentSeller
              ? productsAvailableForConsignment
              : undefined
          }
        />
      ))}

      <div className="row col-lg-12 mt-3 ml-2">
        <button
          type="button"
          className="btn btn-link-secondary p-0"
          onClick={() => dispatchProducts({ type: "ADD" })}
        >
          <ins>+ adicionar outro produto</ins>
        </button>
      </div>

      <div className="col-lg-6">
        <ApiResourceSelect
          label="Lista de preços"
          getOptionLabel={(option: PriceList) => option.name}
          value={priceList}
          onSelect={(option) => handleSetPriceList(option)}
          apiSearchHandler={(typedText) =>
            PriceListService.getPriceListsFiltered({
              name: typedText,
            })
          }
          disabled={formatToFloat(totalValueProducts) === 0}
        />
      </div>
    </div>
  );
};

export default ProductsTab;
