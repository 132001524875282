import React, { useState, useEffect, FormEvent, useCallback } from "react";
import { Button, Spinner, Tabs, Tab, Modal } from "react-bootstrap";
import { MenuItem, TextField } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { format, isBefore } from "date-fns";

import api from "../../services/Api";
import { Installment } from "./Installment";
import { Budget } from "../../types/Budget";
import { ListProducts } from "./ListProducts";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { NumericFormat } from "../../components/NumericFormat";
import { useBudget, Product, InstallmentDetails } from "./context";
import { OrdersSituation } from "../../components/ListWithModalChangeSituation";
import LogService from "../../services/LogService";
import { freightModality } from "../../utils/freightModality";
import CustomerService from "../../services/CustomerService";
import { getSituationText } from "../../utils/getSituationText";
import SellerService from "../../services/SellerService";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import BudgetService from "../../services/BudgetService";
import CustomerSelect from "../../components/CustomerSelect";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import { useSelector } from "react-redux";
import PriceListService from "../../services/PriceListService";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import { PriceList } from "../../types/PriceList";
import CarrierService from "../../services/CarrierService";
import { Carrier } from "../../types/Carrier";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { NewProductModal } from "../../components/Product/NewProductModal";
import useNewProductForm from "../../hooks/newProductForm";
import { Product as GeneralProduct } from "../../types/Product";
import { Product as SelectableProduct } from "../../services/ProductService";
import { CustomerLimitCreditInformation } from "../../types/CustomerLimitCreditInformation";
import { CustomerLimitCreditAlert } from "../../components/CustomerLimitCreditAlert";
import useSellerUser from "../../hooks/sellerUser";
import { yesOrNo } from "../../types/yesOrNo";
import SellerConsignmentService from "../../services/SellerConsignmentService";
import { SellerConsignmentStockLocationSelector } from "../../components/SellerConsignmentStockLocationSelector";
import { Optics } from "./Components/Optics";
import { useCompanyBranch } from "../../hooks/companyBranch";
import { BusinessCategory } from "../../types/BusinessCategory";
import { TOptics } from "../../types/Optics";
import { makeScheduleMessage } from "./watsappSchedule";
import UploadFiles from "../../components/UploadFiles";
import {
  deleteMultipleFiles,
  uploadMultipleFiles,
} from "../../utils/FilesHelper";
export function EditBudgets() {
  const { user } = useSelector((state: any) => state.auth);

  const [isSubmit, setIsSubmit] = useState(false);

  const [activeTab, setActiveTab] = useState("details");

  const [customerId, setCustomerId] = useState<number>();
  const [customerName, setCustomerName] = useState("");
  const [seller, setSeller] = useState("");
  const [freigth, setFreigth] = useState("0");
  const [budgetDate, setBudgetDate] = useState("");
  const [totalValue, setTotalValue] = useState("0");
  const [budgetNumber, setBudgetNumber] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState(1);
  const [modalityFreight, setModalityFreight] = useState("recipient");
  const [totalValueProducts, setTotalValueProducts] = useState("0");
  const [discountMoney, setDiscountMoney] = useState("0");
  const [discountPercentage, setDiscountPercentage] = useState("0");
  const [grossWeight, setGrossWeight] = useState("0");
  const [liquidWeight, setLiquidWeight] = useState("0");

  const [note, setNote] = useState("");
  const [carrier, setCarrier] = useState("");
  const [deadLine, setDeadLine] = useState("");
  const [reference, setReference] = useState("");
  const [insideNote, setInsideNote] = useState("");
  const [priceList, setPriceList] = useState<PriceList>({} as PriceList);
  const [saveEmit, setSaveEmit] = useState(false);
  const [showModalAttention, setShowModalAttention] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [opticsData, setOpticsData] = useState<TOptics | null>(null);
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  const [customerHasError, setCustomerHasError] = useState(false);

  const { selectedCompany } = useCompanyBranch();

  const [
    customerLimitCreditInformation,
    setCustomerLimitCreditInformation,
  ] = useState<CustomerLimitCreditInformation>();
  const [
    sellerConsignmentStockLocationId,
    setSellerConsignmentStockLocationId,
  ] = useState(0);
  const [budgetDataLoaded, setBudgetDataLoaded] = useState(false);

  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const history = useHistory();
  const {
    userSellerInfo,
    productsAvailableForConsignment,
    filterProductsByStockLocationId,
  } = useSellerUser();
  const selectedSellerIsAuthConsignmentSeller =
    !!userSellerInfo &&
    userSellerInfo.isConsignment === yesOrNo.YES &&
    userSellerInfo.id === Number(seller);
  const { id } = useParams<{ id: string }>();

  const {
    classes,
    products,
    installments,
    dispatchProducts,
    dispatchInstallments,
  } = useBudget();

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCustomerChanged = useCallback(
    async (customerId: number | undefined, customerName: string) => {
      if (customerId) {
        const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(
          customerId
        );
        setCustomerLimitCreditInformation(limitCreditInfo);
      } else {
        setCustomerLimitCreditInformation(undefined);
      }
    },
    []
  );

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomerId(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{
    index: number;
    product: Product;
  } | null>(null);
  const {
    showModalNewProduct,
    setShowModalNewProduct,
    newProductDefaultData,
    setNewProductDefaultData,
  } = useNewProductForm();

  const handleCreateProduct = useCallback(
    (createdProduct: GeneralProduct) => {
      if (!productBeingCreated) return;

      const selectableProduct: SelectableProduct = {
        ...(createdProduct as any),
        saleValue: formatToFloat(createdProduct.saleValue),
      };

      const amount = productBeingCreated.product.amount || 1;

      dispatchProducts({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue:
              productBeingCreated.product.unitaryValue ||
              selectableProduct.saleValue,
            total:
              productBeingCreated.product.total ||
              selectableProduct.saleValue * amount,
            INSTANCE: selectableProduct,
          },
        },
      });
    },
    [productBeingCreated]
  );

  const handleClickAddProduct = (
    typedText: string,
    index: number,
    product: Product
  ) => {
    setNewProductDefaultData({ nameProduct: typedText });
    setProductBeingCreated({ index, product });
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    function handleConsignmentSeller() {
      if (!userSellerInfo || userSellerInfo.isConsignment === yesOrNo.NO) {
        return;
      }

      filterProductsByStockLocationId(sellerConsignmentStockLocationId);

      if (!budgetDataLoaded) {
        return;
      }

      dispatchProducts({
        type: "INITIAL",
        payload: { index: 0, products: [] },
      });
      dispatchProducts({ type: "ADD" });
      dispatchInstallments({
        type: "GENERATE",
        payload: {
          amount: 0,
          value: 0,
        },
      });
    }

    handleConsignmentSeller();
  }, [
    userSellerInfo,
    seller,
    sellerConsignmentStockLocationId,
    filterProductsByStockLocationId,
  ]);

  useEffect(() => {
    async function loadData() {
      const { data } = await api.get<Budget>(`budgets/${id}`);
      const dataProducts: Product[] = JSON.parse(data.products);
      const dataInstallments: InstallmentDetails[] = JSON.parse(
        data.installments
      );

      data?.opticsData && setOpticsData(JSON.parse(data?.opticsData));

      const dataAmountInstallments = dataInstallments.length;

      const mappedProducts = dataProducts.map((prod) => {
        return {
          ...prod,
          isLinked: prod.isLinked ?? !!prod.INSTANCE.id,
          name: prod.name ?? prod.INSTANCE.name,
        };
      });

      dispatchProducts({
        type: "INITIAL",
        payload: {
          index: 0,
          products: mappedProducts,
        },
      });

      dispatchInstallments({
        type: "INITIAL",
        payload: {
          installments: dataInstallments,
        },
      });

      const dataPriceList = await PriceListService.getPriceListById(
        data.priceList
      );

      if (dataPriceList) {
        setPriceList(dataPriceList);
      }

      setNote(data.note);
      setSeller(String(data.seller ?? ""));
      setCarrier(data.carrier);
      setCustomerId(data.customerId ?? undefined);
      setCustomerName(data.customerName ?? "");
      setDeadLine(data.deadLine);
      setReference(data.reference);
      setInsideNote(data.insideNote);
      setBudgetDate(data.budgetDate);
      setBudgetNumber(data.budgetNumber);
      setInstallmentAmount(dataAmountInstallments);
      setModalityFreight(data.modalityFreight);
      setSellerConsignmentStockLocationId(
        data.sellerConsignmentStockLocationId || 0
      );

      setFreigth(formatCurrency(data.freigth));
      setTotalValue(formatCurrency(data.totalValue));

      setDiscountMoney(formatCurrency(data.discountMoney));
      setDiscountPercentage(data.discountPercentage);
      setGrossWeight(data.grossWeight);
      setLiquidWeight(data.liquidWeight);
      setFilesNamesSaved(data.attachments ? JSON.parse(data.attachments) : []);
      handleCustomerChanged(data.customerId ?? undefined, data.customerName);
      setBudgetDataLoaded(true);
    }

    loadData();
  }, []);

  useEffect(() => {
    let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

    if (priceList.priceListType === "1") {
      totalProduct = Number(totalProduct) + Number(priceList.value);
    }

    if (priceList.priceListType === "2") {
      totalProduct = Number(totalProduct) - Number(priceList.value);
    }

    const discountNumber = discountMoney ? formatToFloat(discountMoney) : 0;
    const discountPercentageFloat = formatToFloat(discountPercentage);
    const freigthNumber = freigth ? formatToFloat(freigth) : 0;

    const partial = Number(totalProduct) + Number(freigthNumber);

    if (discountNumber > partial) {
      alert("Desconto não pode ser maior que o valor total");

      setTotalValueProducts(formatCurrency(totalProduct));

      setFreigth("0");
      setTotalValue("0");
      setDiscountMoney("0");

      return;
    }

    let total = partial - discountNumber;

    if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
      total = total - total * (discountPercentageFloat / 100);
    }

    setTotalValueProducts(formatCurrency(totalProduct));
    setTotalValue(formatCurrency(total));
  }, [products, discountMoney, discountPercentage, freigth, priceList]);

  useEffect(() => {
    let converted = formatToFloat(discountPercentage);
    if (converted > 100) {
      setDiscountPercentage("100,00");
    } else {
      setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
    }
  }, [discountPercentage]);

  const handleSetSeller = useCallback((value: string | null) => {
    if (value) {
      return setSeller(value);
    }

    return setSeller("");
  }, []);

  const handleSetCarrier = useCallback((value: string | null) => {
    if (value) {
      return setCarrier(value);
    }

    return setCarrier("");
  }, []);

  const handleSetPriceList = useCallback((value: PriceList | null) => {
    if (value) {
      return setPriceList(value);
    }

    return setPriceList({} as PriceList);
  }, []);

  const handleSetDeadLine = useCallback(() => {
    const [year, month, day] = deadLine.split("-");

    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const dateNow = new Date();

    if (isBefore(date, dateNow)) {
      alert("Não é possível adicionar uma data que já passou!");
      return setDeadLine("");
    }
  }, [deadLine]);

  function hasInstallments() {
    var isSubmit = true;

    if (!installments.length) {
      setActiveTab("payment");
      setMsgError("Informe as parcelas do pedido!");
      setShowModalAttention(true);
      setSaveEmit(false);
      isSubmit = false;
    } else {
      installments.map((installment: any, index: number) => {
        if (!installment.value) {
          setActiveTab("payment");
          setMsgError("Informe o valor das parcelas do pedido!");
          setShowModalAttention(true);
          setSaveEmit(false);
          isSubmit = false;
        }
      });
    }

    if (!isSubmit) return isSubmit;

    const sumInstallments = installments.reduce(
      (sum, installment) => sum + installment.value,
      0
    );

    if (sumInstallments !== formatToFloat(totalValue)) {
      setMsgError(
        "A soma das parcelas deve concidir com o valor total do pedido!"
      );
      setShowModalAttention(true);
      isSubmit = false;
      setActiveTab("payment");
    }

    return isSubmit;
  }

  function inputsVerify() {
    setCustomerHasError(false);
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      setProductError(i, product, false);
    }

    if (!customerId && !customerName) {
      setMsgError("Selecione o cliente");
      setCustomerHasError(true);
      return false;
    }

    if (saveEmit && customerId && customerLimitCreditInformation) {
      const totalRequest = formatToFloat(totalValue ?? 0);

      if (
        totalRequest + customerLimitCreditInformation.currentCreditThisPeriod >
        customerLimitCreditInformation.limit
      ) {
        // Se emitir o orçamento ultrapassar o limite de crédito do cliente
        setMsgError(
          "Não é possível emitir o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema."
        );
        setCustomerHasError(true);
        return false;
      }
    }

    const hasProducts = products.find(
      (product) => product.name || product.INSTANCE.id
    );

    if (!hasProducts) {
      setMsgError("Selecione pelo menos 1(um) Produto!");
      setActiveTab("products");
      return false;
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (product.total && !product.INSTANCE?.id && !product.name) {
        setProductError(i, product, true);
        setMsgError("Informe um produto!");
        setActiveTab("products");
        setShowModalAttention(true);
        return false;
      }
    }

    if (
      selectedSellerIsAuthConsignmentSeller &&
      productsAvailableForConsignment
    ) {
      for (const product of products) {
        const valid = SellerConsignmentService.validateConsignmentProductQuantity(
          product.INSTANCE,
          product.amount,
          sellerConsignmentStockLocationId,
          productsAvailableForConsignment
        );
        if (!valid) {
          setActiveTab("products");
          setMsgError(
            `O produto ${product.name} não tem estoque em consignação o suficiente para realizar a venda!`
          );
          setShowModalAttention(true);
          return false;
        }
      }
    }

    if (!hasInstallments()) {
      return false;
    }

    return true;
  }

  function setProductError(index: number, product: Product, error?: boolean) {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...product,
          hasError: error,
        },
      },
    });
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    setIsSubmit(true);

    if (!inputsVerify()) {
      setShowModalAttention(true);
      setSaveEmit(false);
      setIsSubmit(false);
      return;
    }

    // Situação que aparece na listagem
    const situation: OrdersSituation[] = [];

    const lastInputs = await api.get<Budget>(`budgets/${id}`);

    const filesRemained = await deleteMultipleFiles(
      lastInputs.data.attachments
        ? JSON.parse(lastInputs.data.attachments)
        : [],
      filesNamesSaved,
      setShowModalAttention,
      setMsgError,
      setIsSubmit
    );
    let filesPath: any = [];
    if (filesSelected) {
      filesPath = await uploadMultipleFiles(
        filesSelected,
        setShowModalAttention,
        setMsgError,
        setIsSubmit
      );
      if (filesPath === false) {
        return;
      }
    } else {
      filesPath = [];
    }

    situation.push({
      dateSituation: format(Date.now(), "yyyy-MM-dd"),
      commentsSituation: "",
      statusSituation: saveEmit ? "launched" : "open",
    });

    try {
      const rawBudget = {
        customerId: customerId ?? null,
        customerName: !customerId ? customerName : null,
        seller: seller,
        budgetDate,
        budgetNumber,
        modalityFreight,
        carrier,
        deadLine: deadLine,
        reference: reference,
        note: note,
        insideNote: insideNote,
        freigth: formatToFloat(freigth),
        totalValue: formatToFloat(totalValue),
        discountMoney: formatToFloat(discountMoney),
        discountPercentage: formatToFloat(discountPercentage),
        grossWeight: grossWeight,
        liquidWeight: liquidWeight,
        // priceList: priceList.name,

        status: saveEmit ? "launched" : "open",
        sellerConsignmentStockLocationId:
          sellerConsignmentStockLocationId || null,
        products: JSON.stringify(products),
        situation: JSON.stringify(situation),
        installments: JSON.stringify(installments),
        opticsData: JSON.stringify(opticsData),
        attachments: JSON.stringify([...filesRemained, ...filesPath]),
      };

      if (saveEmit) {
        const linkErrors = BudgetService.verifyLinkedFields(rawBudget);

        if (linkErrors.length > 0) {
          setLinkedFieldsErrors(linkErrors);
          setShowModalLinkedFieldsError(true);
          setSaveEmit(false);
          setIsSubmit(false);
          return;
        }
      }

      const response = await api.put(`budgets/${id}`, rawBudget);
      // console.log("response@@", response);

      const previousCustomerEntity = await CustomerService.getCustomerById(
        lastInputs.data.customerId ?? 0
      );
      const newCustomerEntity = await CustomerService.getCustomerById(
        response.data.customerId
      );

      await makeScheduleMessage(
        { ...response.data, customerEntity: newCustomerEntity },
        selectedCompany
      );

      if (saveEmit) {
        const raw = {
          customerId: customerId,
          seller: seller,
          deadLine: deadLine,
          reference: reference,
          note: note,
          insideNote: insideNote,
          requestDate: budgetDate,
          requestNumber: budgetNumber,
          modalityFreight,
          freigth: formatToFloat(freigth),
          totalValue: formatToFloat(totalValue),
          discountMoney: formatToFloat(discountMoney),
          discountPercentage: discountPercentage,
          grossWeight: grossWeight,
          liquidWeight: liquidWeight,

          status: "open",
          products: JSON.stringify(products),
          situation: JSON.stringify(situation),
          installments: JSON.stringify(installments),
          movedToStock: "n",
          attachments: JSON.stringify([...filesRemained, ...filesPath]),
        };

        await api.post("requests", raw);

        setSaveEmit(false);
      }

      history.push("/orcamentos");

      LogService.logEdit({
        itemId: response.data.id,
        itemName: response.data.reference || "Orçamento",
        module: "Orçamentos",
        oldData: {
          ...lastInputs.data,
          customer: CustomerService.getCustomerName(
            previousCustomerEntity,
            lastInputs.data.customerName
          ),
        },
        newData: {
          ...response.data,
          customer: CustomerService.getCustomerName(
            newCustomerEntity,
            response.data.customerName
          ),
        },
        formattedFields: {
          deadLine: (value) =>
            value ? new Date(value).toLocaleDateString() : "",
          requestDate: (value) =>
            value ? new Date(value).toLocaleDateString() : "",
          movedToStock: (value) => getSituationText(value),
          modalityFreight: (value) => freightModality(value),
          seller: (value) => SellerService.getNameByIdAsync(value),
        },
        fieldsMap: {
          customer: "Cliente",
          seller: "Vendedor",
          deadLine: "Prazo de entrega",
          reference: "Referência",
          note: "Observações",
          insideNote: "Observações internas",
          requestDate: "Data do pedido",
          requestNumber: "Nº do pedido",
          modalityFreight: "Modalidade de frete",
          carrier: "Transportadora",
          freight: "Frete",
          discountMoney: "Desconto em dinheiro",
          discountPercentage: "Desconto por porcentagem",
          grossWeight: "Peso bruto",
          liquidWeight: "Peso líquido",
          movedToStock: "Movido para Estoque",
        },
      });
    } catch (error) {
      console.log(error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalAttention(true);
    }

    setIsSubmit(false);
  };

  return (
    <div className="card card-custom gutter-b mt-2 newProductWrapper">
      <ModalLinkedFieldsError
        message="Para emitir é necessário completar o cadastro do Orçamento!"
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalAttention}
        onHide={() => setShowModalAttention(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalAttention(!showModalAttention)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={(evt) => onSubmit(evt)}>
        <div className="card-header row m-0 pt-2 pb-0">
          <div className="col-6 m-0 p-0">
            <h4 className="card-label m-0 mt-2">Detalhes do orçamento</h4>
          </div>

          {user.isAccountant == "n" ? (
            <div className="col-lg-6 d-flex justify-content-end mb-3">
              <Button
                type="submit"
                className="mr-3"
                variant="primary"
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <span>Salvar</span>
                )}
              </Button>

              <Button
                type="submit"
                size="lg"
                variant="secondary"
                onClick={() => setSaveEmit(true)}
              >
                {saveEmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <span>Salvar e Emitir</span>
                )}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="card-body mt-0 pt-0">
          <CustomerLimitCreditAlert
            customerLimitCreditInformation={customerLimitCreditInformation}
          />

          {selectedSellerIsAuthConsignmentSeller && (
            <SellerConsignmentStockLocationSelector
              stockLocationId={sellerConsignmentStockLocationId}
              setStockLocationId={setSellerConsignmentStockLocationId}
            />
          )}

          <div className="row">
            <div className="col-lg-6">
              <CustomerSelect
                label="Cliente"
                hasError={customerHasError}
                isEdit
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerName={customerName}
                setCustomerName={setCustomerName}
                onCustomerChanged={handleCustomerChanged}
                entityId={id}
                entityType="budget"
                disabled={user.isAccountant == "y" ? true : false}
                allowIncomplete
                onClickAddCustomer={handleClickAddCustomer}
              />
            </div>

            <div className="col-lg-4">
              <ApiResourceSelect
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                onSelect={(option) =>
                  setSeller(option ? String(option.id) : "")
                }
                apiSearchHandler={(typedText) =>
                  SellerService.getSellersFiltered({ name: typedText })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(seller)
                    ) ?? SellerService.getSellerById(seller)
                  );
                }}
                disabled={user.isAccountant == "y" ? true : false}
              />
            </div>

            <div className="col-lg-2">
              <TextField
                size="small"
                disabled
                label="Nº pedido"
                margin="normal"
                variant="outlined"
                value={budgetNumber}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Tabs
                activeKey={activeTab}
                onSelect={(tab: string) => setActiveTab(tab)}
                id="newproduct-form-tabs"
              >
                <Tab eventKey="details" title="Detalhes">
                  <div className="row">
                    <div className="col-lg-2 d-flex align-items-center">
                      <TextField
                        size="small"
                        required
                        type="date"
                        label="Data do pedido"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={budgetDate}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        size="small"
                        type="date"
                        margin="normal"
                        variant="outlined"
                        label="Prazo de Entrega"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={deadLine}
                        onBlur={() => handleSetDeadLine()}
                        onChange={(evt) => setDeadLine(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-8">
                      <TextField
                        size="small"
                        label="Referência"
                        margin="normal"
                        variant="outlined"
                        value={reference}
                        onChange={(evt) => setReference(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        size="small"
                        label="Observações"
                        multiline
                        rows="2"
                        placeholder="Esta informação será impressa nas observações da nota."
                        margin="normal"
                        variant="outlined"
                        value={note}
                        onChange={(evt) => setNote(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        size="small"
                        multiline
                        rows="2"
                        margin="normal"
                        variant="outlined"
                        label="Observações Internas"
                        placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                        value={insideNote}
                        onChange={(evt) => setInsideNote(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextField
                        size="small"
                        multiline
                        rows="2"
                        margin="normal"
                        variant="outlined"
                        label="Observações Internas"
                        placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                        value={insideNote}
                        onChange={(evt) => setInsideNote(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-6 ml-2">
                      <UploadFiles
                        label="Contrato/Anexos"
                        filesSelected={filesSelected}
                        setFilesSelected={setFilesSelected}
                        filesNamesSaved={filesNamesSaved}
                        setFilesNamesSaved={setFilesNamesSaved}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="products" title="Produtos">
                  <div className="row">
                    {products.map((prod, index) => (
                      <ListProducts
                        key={index}
                        product={prod}
                        index={index}
                        isEdit
                        entityId={id}
                        onClickAddProduct={handleClickAddProduct}
                        sellerConsignmentProducts={
                          selectedSellerIsAuthConsignmentSeller
                            ? productsAvailableForConsignment
                            : undefined
                        }
                      />
                    ))}

                    {user.isAccountant == "n" ? (
                      <div className="row col-lg-12 mt-3 ml-2">
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={() => dispatchProducts({ type: "ADD" })}
                        >
                          <ins>+ adicionar outro produto</ins>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="col-lg-6">
                      <ApiResourceSelect
                        label="Lista de preços"
                        getOptionLabel={(option: PriceList) => option.name}
                        value={priceList}
                        onSelect={(option) => handleSetPriceList(option)}
                        apiSearchHandler={(typedText) =>
                          PriceListService.getPriceListsFiltered({
                            name: typedText,
                          })
                        }
                        disabled={
                          formatToFloat(totalValueProducts) === 0 ||
                          user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="total-budget" title="Totais do Pedido">
                  <div className="row">
                    <div className="col-lg-3">
                      <NumericFormat
                        label="Valor Total dos produtos"
                        value={totalValueProducts}
                        startAdornment="R$"
                        disabled
                      />
                    </div>

                    <div className="col-lg-3">
                      <TextField
                        size="small"
                        label="Valor do IPI"
                        margin="normal"
                        variant="outlined"
                        disabled
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Valor Total do frete"
                        value={freigth}
                        onChange={(evt) => setFreigth(evt.target.value)}
                        startAdornment="R$"
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Desconto (R$)"
                        value={discountMoney}
                        onChange={(evt) => setDiscountMoney(evt.target.value)}
                        startAdornment="R$"
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Desconto (%)"
                        startAdornment="%"
                        value={discountPercentage}
                        onChange={(evt) =>
                          setDiscountPercentage(evt.target.value)
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Peso Bruto"
                        startAdornment="KG"
                        value={grossWeight}
                        onChange={(evt) => setGrossWeight(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Peso Líquido"
                        startAdornment="KG"
                        value={liquidWeight}
                        onChange={(evt) => setLiquidWeight(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-3 ">
                      <NumericFormat
                        label="Valor total"
                        startAdornment="R$"
                        disabled
                        value={totalValue}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="payment" title="Pagamento">
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        size="small"
                        required
                        type="number"
                        margin="normal"
                        variant="outlined"
                        label="Quantidade de Parcelas"
                        value={installmentAmount}
                        onChange={(evt) =>
                          setInstallmentAmount(Number(evt.target.value))
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>

                    {user.isAccountant == "n" ? (
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <Button
                          variant="primary"
                          size="sm"
                          block
                          onClick={() =>
                            dispatchInstallments({
                              type: "GENERATE",
                              payload: {
                                amount: installmentAmount,
                                value: formatToFloat(totalValue),
                              },
                            })
                          }
                        >
                          Gerar parcelas
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row col-lg-12 mt-10">
                      {installments.length > 0 &&
                        installments.map((installment, index) => (
                          <Installment
                            key={index}
                            index={index}
                            installment={installment}
                            installmentAmount={installmentAmount}
                          />
                        ))}
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="transport" title="Transporte">
                  <div className="row">
                    <div className="col-lg-4">
                      <TextField
                        size="small"
                        select
                        label="Modalidade de Frete"
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                        value={modalityFreight}
                        onChange={(evt) => setModalityFreight(evt.target.value)}
                        disabled={user.isAccountant == "y" ? true : false}
                      >
                        <MenuItem key="0" value="sender">
                          Remetente
                        </MenuItem>

                        <MenuItem key="1" value="recipient">
                          Destinatário
                        </MenuItem>

                        <MenuItem key="5" value="freeShipping">
                          Sem Frete
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-8">
                      <ApiResourceSelect
                        label="Transportadora"
                        freeSolo
                        getOptionLabel={(option: Carrier) => option.name}
                        value={carrier}
                        onSelect={(option) =>
                          handleSetCarrier(option ? String(option.name) : "")
                        }
                        onInputChange={(typedText) =>
                          handleSetCarrier(typedText || "")
                        }
                        apiSearchHandler={(typedText) =>
                          CarrierService.getCarriersFiltered({
                            name: typedText,
                          })
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                      />
                    </div>
                  </div>
                </Tab>
                {selectedCompany?.businessCategory ===
                  BusinessCategory.OPTICS && (
                  <Tab eventKey="optics-tab" title="Ótica">
                    <Optics
                      setOpticsData={setOpticsData}
                      opticsData={opticsData}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
