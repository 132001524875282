import { Company } from "./Company";
import { CompanySubscriptionPlan } from "./CompanySubscriptionPlan";
import { SubscriptionPlan } from "./SubscriptionPlan";

export enum invoiceStatus {
  pending = "pending",
  paid = "paid",
  canceled = "canceled",
  scheduled = "scheduled",
  failed = "failed",
}

export enum invoicePaymentMethod {
  credit_card = "credit_card",
  boleto = "boleto",
  cash = "cash",
  pix = "pix",
}

export type CompanySubscriptionPlanPayment = {
  id: number;
  companyId: number;
  companySubscriptionPlanId: number;
  paymentApiInvoiceId: string;
  installments: number;
  amount: number;
  totalDiscount: number;
  totalIncrement: number;
  status: invoiceStatus;
  paymentMethod: invoicePaymentMethod;
  paymentDate: Date;
  dueDate: Date;
  billingAddress: string;
  billetUrl: string;
  billetBarcode: string;
  billetQrCode: string;
  createdDate: Date;
  updatedDate: Date;

  company: Company;
  companyPlan: CompanySubscriptionPlan;
  subscriptionPlan: SubscriptionPlan;
};
