import React, { useState } from "react";
import { NumericFormat } from "../../NumericFormat";
import { useForm } from "react-hook-form";
import axios from "../../../services/Api";
import { v4 as uuidv4 } from "uuid";
import { SellValueCalculator } from "../../SellValueCalculator";

import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";

import { Button, Modal, ButtonToolbar } from "react-bootstrap";
import ApiResourceSelect from "../../ApiResourceSelect";
import CustomerService from "../../../services/CustomerService";
import { Customer } from "../../../types/Customer";
import BrandService from "../../../services/BrandService";
import { CategoryProduct } from "../../../hooks/products";
import CategoryProductService from "../../../services/CategoryProductService";
import SubCategoryProductService from "../../../services/SubCategoryProductService";
import StockLocationService from "../../../services/StockLocationService";
import { ApiResourceSelectAddButton } from "../../ApiResourceSelectAddButton";
import useNewCustomerForm from "../../../hooks/newCustomerForm";
import { NewCustomerModal } from "../../Customer/NewCustomerModal";
import { NewStockLocationModal } from "../../Parameters/NewStockLocationModal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type Inputs = {
  name: string;
  code: string;
  isVariant: string;
  brand: string;
  unit: string;
  codeGtinEan: string;
  ncm: string;
  stockLocation: string;
  stockMin: string;
  stockMax: string;
  grossWeight: string;
  liquidColumn: string;
  saleValue: string;
  costValue: string;
  productSize: string;
  productOrigin: string;
  ordersNumber: string;
  typeClassification: string;
  situation: string;
  type: string;
  provider: string;
  stockInitial: string;
  note: string;
  category: string;
  subCategory: string;
  icmsRate: string;
  icmsStRate: string;
  cstRate: string;
  mvaRate: string;
  mvaAdjustedRate: string;
  deferralRate: string;
  ipiRate: string;
  pisRate: string;
  cofinsRate: string;
  taxableUnit: string;
  taxBenefitCode: string;
  cestCode: string;
  federal: string;
  state: string;

  nameBrand: string;
  nameCategory: string;
  nameSubCategory: string;
  descriptionCategory: string;
};

type Props = {
  codeGtinEan: string;
  setCodeGtinEan: any;
  situation: string;
  setSituation: React.Dispatch<React.SetStateAction<string>>;
  isKit: string;
  provider: string;
  setProvider: React.Dispatch<React.SetStateAction<string>>;
  buyValue: string;
  setBuyValue: React.Dispatch<React.SetStateAction<string>>;
  creditIcmsPercent: string;
  setCreditIcmsPercent: React.Dispatch<React.SetStateAction<string>>;
  freightMoney: string;
  setFreightMoney: React.Dispatch<React.SetStateAction<string>>;
  freightPercent: string;
  setFreightPercent: React.Dispatch<React.SetStateAction<string>>;
  ipiMoney: string;
  setIpiMoney: React.Dispatch<React.SetStateAction<string>>;
  ipiPercent: string;
  setIpiPercent: React.Dispatch<React.SetStateAction<string>>;
  profitMarginPercent: string;
  setProfitMarginPercent: React.Dispatch<React.SetStateAction<string>>;
  profitMarginMoney: string;
  setProfitMarginMoney: React.Dispatch<React.SetStateAction<string>>;
  creditIcmsstMoney: string;
  setCreditIcmsstMoney: React.Dispatch<React.SetStateAction<string>>;
  creditIcmsstPercent: string;
  setCreditIcmsstPercent: React.Dispatch<React.SetStateAction<string>>;
  sellerValue: string;
  setSellerValue: React.Dispatch<React.SetStateAction<string>>;
  calcPrice: () => void;
  selectedType: string;
  hasVariation: string;
  setHasVariation: React.Dispatch<React.SetStateAction<string>>;
  creditIcmsMoney: string;
  setCreditIcmsMoney: React.Dispatch<React.SetStateAction<string>>;
  showModalPrice: boolean;
  setShowModalPrice: React.Dispatch<React.SetStateAction<boolean>>;
  aditionalCost: string;
  setAditionalCost: React.Dispatch<React.SetStateAction<string>>;
  brandProduct: never[];
  setBrandProduct: React.Dispatch<React.SetStateAction<never[]>>;
  showBrandModal: boolean;
  setShowBrandModal: React.Dispatch<React.SetStateAction<boolean>>;
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  subCategory: string;
  setSubCategory: React.Dispatch<React.SetStateAction<string>>;
  categoryProduct: never[];
  setCategoryProduct: React.Dispatch<React.SetStateAction<never[]>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  addBrand: boolean;
  setAddBrand: React.Dispatch<React.SetStateAction<boolean>>;
  addCategory: boolean;
  setAddCategory: React.Dispatch<React.SetStateAction<boolean>>;
  showSubCategoryField: boolean;
  subCategoryProduct: never[];
  setSubCategoryProduct: React.Dispatch<React.SetStateAction<never[]>>;
  setShowSubCategoryField: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCategoryProductUuid: any;
  setSelectedCategoryProductUuid: React.Dispatch<React.SetStateAction<any>>;
  showSubCategoryModal: boolean;
  setShowSubCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  addSubCategory: boolean;
  setAddSubCategory: React.Dispatch<React.SetStateAction<boolean>>;
  brand: string;
  setBrand: React.Dispatch<React.SetStateAction<string>>;
  //    calcPrice: () => void;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleChangePage: (event: any, newPage: number) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  showBrandModalConfirmDelete: boolean;
  setShowBrandModalConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  showModalConfirmDelete: boolean;
  setShowModalConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  showModalConfirmDeleteSubCategory: boolean;
  setShowModalConfirmDeleteSubCategory: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  idBrandToDelete: number;
  setIdBrandToDelete: React.Dispatch<React.SetStateAction<number>>;
  indexBrandToDelete: number;
  setIndexBrandToDelete: React.Dispatch<React.SetStateAction<number>>;
  idCategoryToDelete: number;
  setIdCategoryToDelete: React.Dispatch<React.SetStateAction<number>>;
  indexCategoryToDelete: number;
  setIndexCategoryToDelete: React.Dispatch<React.SetStateAction<number>>;
  stockInvalid: boolean;
  setStockInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockMinInvalid: boolean;
  setStockMinInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockMaxInvalid: boolean;
  setStockMaxInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockInitialInvalid: boolean;
  setStockInitialInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  typeInvalid: boolean;
  setTypeInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  nameInvalid: boolean;
  setNameInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  unitInvalid: boolean;
  setUnitInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  saleValueInvalid: boolean;
  setSaleValueInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  productByGtinEanCodeInvalid: boolean;
  setProductByGtinEanCodeInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  saleValueApply: string;
  setSaleValueApply: React.Dispatch<React.SetStateAction<string>>;
  stockLocation: string;
  setStockLocation: React.Dispatch<React.SetStateAction<string>>;
  sendStockNotification: string;
  setSendStockNotification: React.Dispatch<React.SetStateAction<any>>;
};

type StockLocations = {
  id: number;
  name: string;
  description: string;
};

export function NewProductDetails({
  codeGtinEan,
  setCodeGtinEan,
  situation,
  setSituation,
  isKit,
  provider,
  setProvider,
  showModalPrice,
  setShowModalPrice,
  selectedType,
  hasVariation,
  brandProduct,
  setBrandProduct,
  showBrandModal,
  setShowBrandModal,
  addBrand,
  setAddBrand,
  idBrandToDelete,
  setIdBrandToDelete,
  indexBrandToDelete,
  showBrandModalConfirmDelete,
  setIndexBrandToDelete,
  setShowBrandModalConfirmDelete,
  category,
  setCategory,
  subCategory,
  setSubCategory,
  categoryProduct,
  setCategoryProduct,
  subCategoryProduct,
  setSubCategoryProduct,
  showModal,
  setShowModal,
  addCategory,
  setAddCategory,
  showSubCategoryField,
  setShowSubCategoryField,
  selectedCategoryProductUuid,
  setSelectedCategoryProductUuid,
  showSubCategoryModal,
  page,
  handleChange,
  rowsPerPage,
  setShowSubCategoryModal,
  addSubCategory,
  setAddSubCategory,
  setRowsPerPage,
  handleChangePage,
  idCategoryToDelete,
  setIdCategoryToDelete,
  indexCategoryToDelete,
  showModalConfirmDelete,
  setIndexCategoryToDelete,
  setShowModalConfirmDelete,
  showModalConfirmDeleteSubCategory,
  setShowModalConfirmDeleteSubCategory,
  stockInvalid,
  setStockInvalid,
  stockMinInvalid,
  setStockMinInvalid,
  stockMaxInvalid,
  setStockMaxInvalid,
  stockInitialInvalid,
  setStockInitialInvalid,
  unitInvalid,
  setUnitInvalid,
  saleValueInvalid,
  setSaleValueInvalid,
  productByGtinEanCodeInvalid,
  setProductByGtinEanCodeInvalid,
  saleValueApply,
  setSaleValueApply,
  brand,
  setBrand,
  stockLocation,
  setStockLocation,
  sendStockNotification,
  setSendStockNotification,
}: Props) {
  const classes = useStyles();

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setProvider(String(createdCustomer.id));
  };

  const handleCreateStockLocation = (createdStockLocation: StockLocations) => {
    setStockLocation(String(createdStockLocation.id));
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({
      name: typedText,
      typeRegister: "supplier",
    });
    setShowModalNewCustomer(true);
  };

  const {
    register,
    formState: { errors },
  } = useForm<Inputs>();
  const [brandNameInput, setBrandNameInput] = useState("");
  const [categoryNameInput, setCategoryNameInput] = useState("");
  const [subCategoryNameInput, setSubCategoryNameInput] = useState("");
  const [categoryDescriptionInput, setCategoryDescriptionInput] = useState("");

  const [
    newStockLocationDefaultData,
    setNewStockLocationDefaultData,
  ] = useState("");
  const [showModalNewStockLocation, setShowModalNewStockLocation] = useState(
    false
  );
  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  function applySellValue(value: number) {
    setSaleValueApply(String(value).replace(".", ","));
    setSaleValueInvalid(false);
  }

  async function registerBrandInput() {
    const uuidBrand = uuidv4();

    var raw = {
      uuidBrand: uuidBrand,
      nameBrand: brandNameInput,
    };

    try {
      await axios.post("/brand", raw);

      // setAddBrand(!addBrand);

      setShowBrandModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteBrand(idBrand: number, index: number) {
    try {
      var arrBrand = brandProduct;
      arrBrand.splice(index, 1);
      setBrandProduct(arrBrand);

      setShowBrandModalConfirmDelete(false);
      setShowBrandModal(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function registerCategoryInput() {
    const uuidCategory = uuidv4();

    var raw = {
      uuidCategory: uuidCategory,
      nameCategory: categoryNameInput,
      description: categoryDescriptionInput,
    };

    try {
      await axios.post("/categoryproduct", raw);

      // setAddCategory(!addCategory);

      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteCategory(idCategory: number, index: number) {
    try {
      var arrCategory = categoryProduct;
      arrCategory.splice(index, 1);
      setCategoryProduct(arrCategory);

      setShowModalConfirmDelete(false);
      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function registerSelectedCategoryProductUuid(idCategory: number) {
    if (!idCategory) {
      setShowSubCategoryField(false);
    }

    try {
      const category = await axios.get(`/categoryproduct/${idCategory}`);

      setSelectedCategoryProductUuid(category.data.uuidCategory);

      setShowSubCategoryField(true);
    } catch (error) {
      console.log(error);
      setShowSubCategoryField(false);
    }
  }

  async function registerSubCategoryInput() {
    var raw = {
      uuidCategory: selectedCategoryProductUuid,
      nameSubCategory: subCategoryNameInput,
    };

    try {
      await axios.post("/subcategoryproduct", raw);

      // setAddSubCategory(!addSubCategory);

      setShowSubCategoryModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteSubCategory(idSubCategory: number, index: number) {
    try {
      var arrSubCategory = subCategoryProduct;
      arrSubCategory.splice(index, 1);
      setSubCategoryProduct(arrSubCategory);

      setShowModalConfirmDeleteSubCategory(false);
      setShowSubCategoryModal(true);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickAddStockLocation = (typedText: string) => {
    setNewStockLocationDefaultData(typedText);
    setShowModalNewStockLocation(true);
  };

  return (
    <div className="newProductWrapper">
      <Modal
        show={showModalError}
        onHide={() => setShowModalError(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalError(!showModalError)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
        allowedType="supplier"
      />

      <NewStockLocationModal
        showModal={showModalNewStockLocation}
        setShowModal={setShowModalNewStockLocation}
        onCreateStockLocation={handleCreateStockLocation}
        defaultData={newStockLocationDefaultData}
      />

      <Grid container spacing={3}>
        <Grid item md={2}>
          <TextField
            id="outlined-select-currency"
            select
            label="Unidade"
            size="small"
            required
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            {...register("unit", {
              required: "Campo Obrigatório!",
            })}
            onChange={(e) => {
              handleChange(e);
              setUnitInvalid(e.target.value ? false : true);
            }}
            error={unitInvalid}
          >
            <MenuItem key="pc" value="Pç">
              Pç
            </MenuItem>
            <MenuItem key="kg" value="Kg">
              Kg
            </MenuItem>
            <MenuItem key="un" value="Un">
              Un
            </MenuItem>
          </TextField>
          {errors.unit && errors.unit.type === "required" && (
            <span className="text-danger pl-3">{errors.unit.message}</span>
          )}
        </Grid>

        <Modal
          show={showBrandModalConfirmDelete}
          onHide={() => {
            setShowBrandModalConfirmDelete(false);
            setShowBrandModal(true);
          }}
          aria-labelledby="contained-modal-warning"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
              Atenção
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Tem certeza que deseja excluir este registro ?</b>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                deleteBrand(idBrandToDelete, indexBrandToDelete);
              }}
            >
              Excluir
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                setShowBrandModalConfirmDelete(!showBrandModalConfirmDelete)
              }
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

        <Grid
          item
          md={3}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="col-9">
            <ApiResourceSelect
              label="Marca"
              noOptionsText="Nenhuma marca encontrada"
              getOptionLabel={(option: any) => option.nameBrand}
              value={brand}
              onSelect={(option) => setBrand(String(option?.id ?? ""))}
              apiSearchHandler={(typedText) =>
                BrandService.getBrandsFiltered({ name: typedText })
              }
              getSelectedOption={(loadedOptions) => {
                if (!brand) return null;
                return (
                  loadedOptions.find((option) => option.id === Number(brand)) ??
                  BrandService.getBrandById(brand)
                );
              }}
            />
          </div>

          <div className="col-3">
            <ButtonToolbar className="ml-4">
              <Button
                variant="primary"
                onClick={() => setShowBrandModal(!showBrandModal)}
              >
                +
              </Button>

              <Modal
                show={showBrandModal}
                onHide={() => setShowBrandModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Adicionar marcas
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className={addBrand ? "" : "pb-0"}>
                  {addBrand ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <TextField
                            label="Marca"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            value={brandNameInput}
                            onChange={(e) => setBrandNameInput(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} className="pl-3">
                        <Grid
                          item
                          md={3}
                          className="d-flex align-items-center pl-0"
                        >
                          <Button
                            type="button"
                            variant="success"
                            onClick={() => {
                              registerBrandInput();
                            }}
                          >
                            Salvar
                          </Button>
                          {/* <Button
                                                variant='secondary'
                                                className='ml-3'
                                                onClick={() =>
                                                    setAddBrand(!addBrand)
                                                }
                                                >
                                                Cancelar
                                                </Button> */}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={3}>
                        <Grid item md={3}>
                          <Button
                            variant="success"
                            onClick={() => setAddBrand(!addBrand)}
                          >
                            Adicionar marcas
                          </Button>
                        </Grid>
                      </Grid>

                      {!brandProduct.length ? (
                        <div className="bg-warning-o-30 text-center p-5 mt-5">
                          Não há <b>marcas</b> cadastradas.
                        </div>
                      ) : (
                        <Paper className="mt-5">
                          <div>
                            <Table aria-labelledby="tableTitle" size="medium">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    key={0}
                                    align="left"
                                    padding="default"
                                    // sortDirection={orderBy === row.id ? order : false}
                                  >
                                    Nº
                                  </TableCell>

                                  <TableCell
                                    key={1}
                                    align="left"
                                    padding="default"
                                    // sortDirection={orderBy === row.id ? order : false}
                                  >
                                    Marca
                                  </TableCell>

                                  <TableCell
                                    key={2}
                                    align="left"
                                    padding="default"
                                    // sortDirection={orderBy === row.id ? order : false}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {brandProduct.map(
                                  ({ id, nameBrand }, index) => {
                                    const labelId = `enhanced-table-checkbox-${id}`;

                                    return (
                                      <>
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={id}
                                          style={{
                                            cursor: "pointer",
                                            padding: "16px",
                                          }}
                                        >
                                          <TableCell
                                            align="left"
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                          >
                                            {id}
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            component="th"
                                            id={id}
                                            scope="row"
                                            padding="default"
                                          >
                                            {nameBrand}
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            padding="default"
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-light-danger font-weight-bold p-2"
                                              onClick={() => {
                                                setShowBrandModal(false);
                                                setShowBrandModalConfirmDelete(
                                                  true
                                                );
                                                setIdBrandToDelete(id);
                                                setIndexBrandToDelete(index);
                                              }}
                                            >
                                              <i className="flaticon-delete p-0"></i>
                                            </button>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </div>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={brandProduct.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                              "aria-label": "Página Anterior",
                            }}
                            nextIconButtonProps={{
                              "aria-label": "Próxima Página",
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={(event) =>
                              setRowsPerPage(+event.target.value)
                            }
                          />
                        </Paper>
                      )}
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowBrandModal(false)}
                  >
                    Fechar
                  </Button>
                </Modal.Footer>
              </Modal>
            </ButtonToolbar>
          </div>
        </Grid>

        <Grid item md={3}>
          <TextField
            label="Código GTIN/EAN"
            size="small"
            margin="normal"
            variant="outlined"
            value={codeGtinEan}
            onChange={(e) => {
              setCodeGtinEan(e.target.value);
              setProductByGtinEanCodeInvalid(e.target.value ? false : true);
            }}
            error={productByGtinEanCodeInvalid}
          />
          {errors.codeGtinEan && errors.codeGtinEan.type === "pattern" && (
            <span className="text-danger pl-3">
              {errors.codeGtinEan.message}
            </span>
          )}
        </Grid>

        <Grid item md={4}>
          <TextField
            id="outlined-select-currency"
            select
            size="small"
            label="Tipo de classificação"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            {...register("typeClassification")}
            onChange={(e) => handleChange(e)}
          >
            <MenuItem key="" value="">
              Selecione
            </MenuItem>

            <MenuItem key="0" value="00 - Mercadoria para Revenda">
              00 - Mercadoria para Revenda
            </MenuItem>

            <MenuItem key="1" value="01 - Matéria-Prima">
              01 - Matéria-Prima
            </MenuItem>

            <MenuItem key="2" value="02 - Embalagem">
              02 - Embalagem
            </MenuItem>

            <MenuItem key="3" value="03 - Produto em Processo">
              03 - Produto em Processo
            </MenuItem>

            <MenuItem key="4" value="04 - Produto Acabado">
              04 - Produto Acabado
            </MenuItem>

            <MenuItem key="5" value="05 - Subproduto">
              05 - Subproduto
            </MenuItem>

            <MenuItem key="6" value="06 - Produto Intermediário">
              06 - Produto Intermediário
            </MenuItem>

            <MenuItem key="7" value="07 - Material de Uso e Consumo">
              07 - Material de Uso e Consumo
            </MenuItem>

            <MenuItem key="8" value="08 - Ativo Imobilizado">
              08 - Ativo Imobilizado
            </MenuItem>

            <MenuItem key="9" value="09 - Serviços">
              09 - Serviços
            </MenuItem>

            <MenuItem key="10" value="10 - Outros insumos">
              10 - Outros insumos
            </MenuItem>

            <MenuItem key="99" value="99 - Outras">
              99 - Outras
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {hasVariation === "y" || isKit === "y"
        ? ""
        : selectedType === "product" && (
            <Grid container spacing={3}>
              <Grid item md={3}>
                <ApiResourceSelect
                  style={{ width: "100%" }}
                  label="Localização no estoque"
                  getOptionLabel={(option: StockLocations) => option.name}
                  value={stockLocation}
                  onSelect={(option) => {
                    setStockLocation(String(option?.id ?? ""));
                    setStockInvalid(option ? false : true);
                  }}
                  apiSearchHandler={(typedText) =>
                    StockLocationService.getStockLocationsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!stockLocation) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(stockLocation)
                      ) ??
                      StockLocationService.getStockLocationById(stockLocation)
                    );
                  }}
                  hasError={stockInvalid}
                  renderAddButton={(typedText) => (
                    <ApiResourceSelectAddButton
                      label="Adicionar um Local de Estoque"
                      onClick={() => handleClickAddStockLocation(typedText)}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <NumericFormat
                  label="Estoque mínimo"
                  {...register("stockMin")}
                  onChange={(e) => {
                    handleChange(e);
                    setStockMinInvalid(e.target.value ? false : true);
                  }}
                  error={stockMinInvalid}
                  required={selectedType == "product" ? false : true}
                />
              </Grid>

              <Grid item md={3}>
                <NumericFormat
                  label="Estoque máximo"
                  {...register("stockMax")}
                  onChange={(e) => {
                    handleChange(e);
                    setStockMaxInvalid(e.target.value ? false : true);
                  }}
                  error={stockMaxInvalid}
                  required={selectedType == "product" ? false : true}
                />
              </Grid>

              <Grid item md={3}>
                <NumericFormat
                  label="Estoque inicial"
                  {...register("stockInitial")}
                  onChange={(e) => {
                    handleChange(e);
                    setStockInitialInvalid(e.target.value ? false : true);
                  }}
                  error={stockInitialInvalid}
                  required={selectedType == "product" ? false : true}
                />
              </Grid>
            </Grid>
          )}
      <Grid container spacing={3}>
        <Grid item md={3}>
          <NumericFormat
            label="Valor de venda"
            className={classes.error}
            required
            startAdornment="R$"
            {...register("saleValue")}
            value={saleValueApply}
            onChange={(e) => {
              handleChange(e);
              setSaleValueInvalid(e.target.value ? false : true);
              setSaleValueApply(e.target.value);
            }}
            error={saleValueInvalid}
          />
        </Grid>

        <Grid item md={3}>
          <NumericFormat
            label="Valor de custo"
            startAdornment="R$"
            {...register("costValue")}
            onChange={(e) => handleChange(e)}
          />
        </Grid>

        <Grid item md={3} className="d-flex align-items-center">
          <ButtonToolbar>
            <Button
              variant="primary"
              onClick={() => setShowModalPrice(!showModalPrice)}
            >
              Calcular valor de venda
              <i className="flaticon-coins ml-2"></i>
            </Button>

            <SellValueCalculator
              isModalOpen={showModalPrice}
              setIsModalOpen={setShowModalPrice}
              applySellValue={applySellValue}
            />
          </ButtonToolbar>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={3}>
          <NumericFormat
            label="Tamanho do produto"
            startAdornment="cm"
            {...register("productSize")}
            onChange={(e) => handleChange(e)}
          />
        </Grid>

        <Grid item md={3}>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Número da ordem"
            {...register("ordersNumber")}
            onChange={(e) => handleChange(e)}
          />
        </Grid>

        <Grid item md={2}>
          <TextField
            size="small"
            id="outlined-select-currency"
            select
            label="Situação"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            onChange={(e) => setSituation(e.target.value)}
            value={situation}
          >
            <MenuItem key="active" value="active">
              Ativo
            </MenuItem>

            <MenuItem key="inactive" value="inactive">
              Inativo
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item md={2}>
          <TextField
            size="small"
            id="outlined-select-currency"
            select
            label="Notifica Estoque"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            onChange={(e) => setSendStockNotification(e.target.value)}
            value={sendStockNotification}
          >
            <MenuItem key="active" value="y">
              Sim
            </MenuItem>

            <MenuItem key="inactive" value="n">
              Não
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item md={2}>
          <ApiResourceSelect
            label="Fornecedor"
            getOptionLabel={(option: Customer) =>
              `${option.id} - ${option.name}`
            }
            value={provider}
            onSelect={(option) => setProvider(option ? String(option.id) : "")}
            apiSearchHandler={(typedText) =>
              CustomerService.getCustomersFiltered({
                name: typedText,
                nameWithId: true,
                typeRegister: "supplier",
              })
            }
            getSelectedOption={(loadedOptions) => {
              if (!provider) return null;
              return (
                loadedOptions.find(
                  (option) => option.id === Number(provider)
                ) ?? CustomerService.getCustomerById(provider)
              );
            }}
            renderAddButton={(typedText) => (
              <ApiResourceSelectAddButton
                label="Adicionar Fornecedor"
                onClick={() => handleClickAddCustomer(typedText)}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={12}>
          <TextField
            label="Observações"
            multiline
            rows="2"
            margin="normal"
            variant="outlined"
            {...register("note")}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Modal
          show={showModalConfirmDelete}
          onHide={() => {
            setShowModalConfirmDelete(false);
            setShowModal(true);
          }}
          aria-labelledby="contained-modal-warning"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
              Atenção
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Tem certeza que deseja excluir este registro ?</b>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                deleteCategory(idCategoryToDelete, indexCategoryToDelete);
              }}
            >
              Excluir
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowModalConfirmDelete(!showModalConfirmDelete)}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

        <Grid
          item
          md={5}
          className="mb-10 d-flex justify-content-between align-items-center"
        >
          <ApiResourceSelect
            label="Categoria"
            style={{ width: "100%" }}
            noOptionsText="Nenhuma categoria encontrada"
            getOptionLabel={(option: CategoryProduct) => option.nameCategory}
            value={category}
            onSelect={(option) => {
              setCategory(String(option?.id ?? ""));
              registerSelectedCategoryProductUuid(option?.id ?? 0);
            }}
            apiSearchHandler={(typedText) =>
              CategoryProductService.getCategoriesFiltered({ name: typedText })
            }
            getSelectedOption={(loadedOptions) => {
              if (!category) return null;
              return (
                loadedOptions.find(
                  (option) => option.id === Number(category)
                ) ?? CategoryProductService.getCategoryById(category)
              );
            }}
          />
          <ButtonToolbar className="ml-4">
            <Button
              variant="primary"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              +
            </Button>

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Adicionar categorias
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={addCategory ? "" : "pb-0"}>
                {addCategory ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <TextField
                          size="small"
                          label="Categoria"
                          margin="normal"
                          variant="outlined"
                          value={categoryNameInput}
                          onChange={(e) => setCategoryNameInput(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md={9}>
                        <TextField
                          size="small"
                          label="Descrição"
                          margin="normal"
                          variant="outlined"
                          value={categoryDescriptionInput}
                          onChange={(e) =>
                            setCategoryDescriptionInput(e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pl-3">
                      <Grid
                        item
                        md={3}
                        className="d-flex align-items-center pl-0"
                      >
                        <Button
                          type="button"
                          variant="success"
                          onClick={() => {
                            registerCategoryInput();
                          }}
                        >
                          Salvar
                        </Button>
                        {/* <Button
                                        variant='secondary'
                                        className='ml-3'
                                        onClick={() =>
                                            setAddCategory(!addCategory)
                                        }
                                        >
                                        Cancelar
                                        </Button> */}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <Button
                          variant="success"
                          onClick={() => setAddCategory(!addCategory)}
                        >
                          Adicionar categorias
                        </Button>
                      </Grid>
                    </Grid>

                    {!categoryProduct.length ? (
                      <div className="bg-warning-o-30 text-center p-5 mt-5">
                        Não há <b>categorias</b> cadastradas.
                      </div>
                    ) : (
                      <Paper className="mt-5">
                        <div>
                          <Table aria-labelledby="tableTitle" size="medium">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  key={0}
                                  align="left"
                                  padding="default"
                                  // sortDirection={orderBy === row.id ? order : false}
                                >
                                  Nº
                                </TableCell>

                                <TableCell
                                  key={1}
                                  align="left"
                                  padding="default"
                                  // sortDirection={orderBy === row.id ? order : false}
                                >
                                  Categoria
                                </TableCell>

                                <TableCell
                                  key={2}
                                  align="left"
                                  padding="default"
                                  // sortDirection={orderBy === row.id ? order : false}
                                ></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {categoryProduct.map(
                                ({ id, nameCategory }, index) => {
                                  const labelId = `enhanced-table-checkbox-${id}`;

                                  return (
                                    <>
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={id}
                                        style={{
                                          cursor: "pointer",
                                          padding: "16px",
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          component="th"
                                          id={labelId}
                                          scope="row"
                                          padding="default"
                                        >
                                          {id}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          component="th"
                                          id={id}
                                          scope="row"
                                          padding="default"
                                        >
                                          {nameCategory}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                          padding="default"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-light-danger font-weight-bold p-2"
                                            onClick={() => {
                                              setShowModal(false);
                                              setShowModalConfirmDelete(true);
                                              setIdCategoryToDelete(id);
                                              setIndexCategoryToDelete(index);
                                            }}
                                          >
                                            <i className="flaticon-delete p-0"></i>
                                          </button>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </div>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={categoryProduct.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          backIconButtonProps={{
                            "aria-label": "Página Anterior",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima Página",
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={(event) =>
                            setRowsPerPage(+event.target.value)
                          }
                        />
                      </Paper>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </ButtonToolbar>
        </Grid>

        {/* MODAL EXCLUIR SUBCATEGORIA */}
        <Modal
          show={showModalConfirmDeleteSubCategory}
          onHide={() => {
            setShowModalConfirmDeleteSubCategory(false);
            setShowSubCategoryModal(true);
          }}
          aria-labelledby="contained-modal-warning"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
              Atenção
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Tem certeza que deseja excluir este registro SUBCAT?</b>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                deleteSubCategory(idCategoryToDelete, indexCategoryToDelete);
              }}
            >
              Excluir
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                setShowModalConfirmDeleteSubCategory(
                  !showModalConfirmDeleteSubCategory
                )
              }
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

        <Grid
          item
          md={5}
          className="mb-10 d-flex justify-content-between align-items-center"
        >
          {showSubCategoryField ? (
            <>
              <ApiResourceSelect
                style={{ width: "100%" }}
                label="Sub categoria"
                noOptionsText="Nenhuma sub categoria encontrada"
                getOptionLabel={(option: any) => option.nameSubCategory}
                value={subCategory}
                onSelect={(option) => setSubCategory(String(option?.id ?? ""))}
                apiSearchHandler={(typedText) =>
                  SubCategoryProductService.getSubCategoriesFiltered(
                    selectedCategoryProductUuid,
                    { name: typedText }
                  )
                }
                getSelectedOption={(loadedOptions) => {
                  if (!subCategory) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(subCategory)
                    ) ??
                    SubCategoryProductService.getSubCategoryById(subCategory)
                  );
                }}
              />

              <ButtonToolbar>
                <Button
                  variant="primary"
                  onClick={() => setShowSubCategoryModal(!showSubCategoryModal)}
                >
                  +
                </Button>

                <Modal
                  show={showSubCategoryModal}
                  onHide={() => setShowSubCategoryModal(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Adicionar sub categorias
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className={addSubCategory ? "" : "pb-0"}>
                    {addSubCategory ? (
                      <>
                        <Grid container spacing={3}>
                          <Grid item md={6}>
                            <TextField
                              size="small"
                              label="Sub Categoria"
                              margin="normal"
                              variant="outlined"
                              value={subCategoryNameInput}
                              onChange={(e) =>
                                setSubCategoryNameInput(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} className="pl-3">
                          <Grid
                            item
                            md={3}
                            className="d-flex align-items-center"
                          >
                            <Button
                              type="button"
                              variant="success"
                              onClick={() => {
                                registerSubCategoryInput();
                              }}
                            >
                              Salvar
                            </Button>
                            {/* <Button
                                                variant='secondary'
                                                className='ml-3'
                                                onClick={() =>
                                                    setAddSubCategory(!addSubCategory)
                                                }
                                                >
                                                Cancelar
                                                </Button> */}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container spacing={4}>
                          <Grid item md={4}>
                            <Button
                              variant="success"
                              onClick={() => setAddSubCategory(!addSubCategory)}
                            >
                              Adicionar sub categorias
                            </Button>
                          </Grid>
                        </Grid>

                        {!subCategoryProduct.length ? (
                          <div className="bg-warning-o-30 text-center p-5 mt-5">
                            Não há <b>sub categorias</b> cadastradas.
                          </div>
                        ) : (
                          <Paper className="mt-5">
                            <div>
                              <Table aria-labelledby="tableTitle" size="medium">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      key={0}
                                      align="left"
                                      padding="default"
                                      // sortDirection={orderBy === row.id ? order : false}
                                    >
                                      Nº
                                    </TableCell>

                                    <TableCell
                                      key={1}
                                      align="left"
                                      padding="default"
                                      // sortDirection={orderBy === row.id ? order : false}
                                    >
                                      Sub Categoria
                                    </TableCell>

                                    <TableCell
                                      key={2}
                                      align="left"
                                      padding="default"
                                      // sortDirection={orderBy === row.id ? order : false}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {subCategoryProduct.map(
                                    (
                                      { id, uuidCategory, nameSubCategory },
                                      index
                                    ) => {
                                      const labelId = `enhanced-table-checkbox-${id}`;
                                      if (
                                        uuidCategory ===
                                        selectedCategoryProductUuid
                                      ) {
                                        return (
                                          <>
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              tabIndex={-1}
                                              key={id}
                                              style={{
                                                cursor: "pointer",
                                                padding: "16px",
                                              }}
                                            >
                                              <TableCell
                                                align="left"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                              >
                                                {id}
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                component="th"
                                                id={id}
                                                scope="row"
                                                padding="default"
                                              >
                                                {nameSubCategory}
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                component="th"
                                                scope="row"
                                                padding="default"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-light-danger font-weight-bold p-2"
                                                  onClick={() => {
                                                    setShowSubCategoryModal(
                                                      false
                                                    );
                                                    setShowModalConfirmDeleteSubCategory(
                                                      true
                                                    );
                                                    setIdCategoryToDelete(id);
                                                    setIndexCategoryToDelete(
                                                      index
                                                    );
                                                  }}
                                                >
                                                  <i className="flaticon-delete p-0"></i>
                                                </button>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </div>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={categoryProduct.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              backIconButtonProps={{
                                "aria-label": "Página Anterior",
                              }}
                              nextIconButtonProps={{
                                "aria-label": "Próxima Página",
                              }}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={(event) =>
                                setRowsPerPage(+event.target.value)
                              }
                            />
                          </Paper>
                        )}
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowSubCategoryModal(false)}
                    >
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </ButtonToolbar>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
