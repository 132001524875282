import React from "react";
import { TextField } from "@material-ui/core";
import { NumericFormat } from "../../../components/NumericFormat";

interface TotalRequestTabProps {
  totalValueProducts: string;
  freigth: string;
  setFreigth: (value: string) => void;
  discountMoney: string;
  setDiscountMoney: (value: string) => void;
  discountPercentage: string;
  setDiscountPercentage: (value: string) => void;
  grossWeight: string;
  setGrossWeight: (value: string) => void;
  liquidWeight: string;
  setLiquidWeight: (value: string) => void;
  totalValue: string;
}

const TotalRequestTab: React.FC<TotalRequestTabProps> = ({
  totalValueProducts,
  freigth,
  setFreigth,
  discountMoney,
  setDiscountMoney,
  discountPercentage,
  setDiscountPercentage,
  grossWeight,
  setGrossWeight,
  liquidWeight,
  setLiquidWeight,
  totalValue,
}) => {
  return (
    <div className="row">
      <div className="col-lg-3">
        <NumericFormat
          disabled
          label="Valor Total dos produtos"
          value={totalValueProducts}
          startAdornment="R$"
        />
      </div>

      <div className="col-lg-3">
        <TextField
          size="small"
          label="Valor do IPI"
          margin="normal"
          variant="outlined"
          disabled
        />
      </div>

      <div className="col-lg-3">
        <NumericFormat
          label="Valor Total do frete"
          startAdornment="R$"
          value={freigth}
          onChange={(evt) => setFreigth(evt.target.value)}
        />
      </div>

      <div className="col-lg-3">
        <NumericFormat
          label="Desconto"
          startAdornment="R$"
          value={discountMoney}
          onChange={(evt) => setDiscountMoney(evt.target.value)}
        />
      </div>

      <div className="col-lg-3">
        <NumericFormat
          className="inputSmaller"
          label="Desconto"
          startAdornment="%"
          value={discountPercentage}
          onChange={(e) => setDiscountPercentage(e.target.value)}
        />
      </div>

      <div className="col-lg-3">
        <NumericFormat
          label="Peso Bruto"
          value={grossWeight}
          onChange={(e) => setGrossWeight(e.target.value)}
        />
      </div>

      <div className="col-lg-3">
        <NumericFormat
          label="Peso Líquido"
          value={liquidWeight}
          onChange={(e) => setLiquidWeight(e.target.value)}
        />
      </div>

      <div className="col-lg-3 ">
        <NumericFormat
          disabled
          label="Valor total"
          value={totalValue}
          startAdornment="R$"
        />
      </div>
    </div>
  );
};

export default TotalRequestTab;
