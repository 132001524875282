import React, { useRef } from "react";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import { TOptics } from "../../../types/Optics";

interface IProps {
  setOpticsData: (value: TOptics | any) => void;
  opticsData?: TOptics | any;
}
// props: IProps
export const Optics = ({ setOpticsData, opticsData }: IProps) => {
  const changePropValue = (propName: string, value: string | undefined) => {
    if (value) {
      setOpticsData((state: TOptics) => ({ ...state, [propName]: value }));
    } else {
      setOpticsData((state: TOptics) => ({ ...state, [propName]: value + "" }));
    }
  };

  const fieldRefs = useRef<Array<HTMLInputElement | null>>([]);

  const addFieldRef = (ref: HTMLInputElement | null) => {
    if (ref && !fieldRefs.current.includes(ref)) {
      fieldRefs.current.push(ref);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <div
          className="row"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "row",
          }}
        >
          <div
            className="col-lg-1"
            style={{ paddingTop: "22px", maxWidth: "53px" }}
          >
            <label
              style={{
                backgroundColor: "#f2f2f2",
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
                padding: "10px",
                height: "86px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              LONGE
            </label>
          </div>
          <div
            className="col-lg-11"
            style={{
              paddingLeft: "25px",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{
                paddingTop: "22px",
              }}
            >
              <div></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <TextField
                  tabIndex={1}
                  label="OD Esférico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeSphereFar}
                  inputRef={(ref) => addFieldRef(ref)}
                  onChange={(evt) =>
                    changePropValue("rightEyeSphereFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Esférico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeSphereFar}
                  onChange={(evt) =>
                    changePropValue("leftEyeSphereFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  tabIndex={2}
                  inputRef={(ref) => addFieldRef(ref)}
                  label="OD Cilíndrico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeCylinderFar}
                  onChange={(evt) =>
                    changePropValue("rightEyeCylinderFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Cilíndrico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeCylinderFar}
                  onChange={(evt) =>
                    changePropValue("leftEyeCylinderFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Eixo"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeAxisFar}
                  onChange={(evt) =>
                    changePropValue("rightEyeAxisFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Eixo"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeAxisFar}
                  onChange={(evt) =>
                    changePropValue("leftEyeAxisFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD DNP"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeDnpFar}
                  onChange={(evt) =>
                    changePropValue("rightEyeDnpFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE DNP"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeDnpFar}
                  onChange={(evt) =>
                    changePropValue("leftEyeDnpFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Alt"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeAltFar}
                  onChange={(evt) =>
                    changePropValue("rightEyeAltFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Alt"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeAltFar}
                  onChange={(evt) =>
                    changePropValue("leftEyeAltFar", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "row",
          }}
        >
          <div
            className="col-lg-1"
            style={{ paddingTop: "0px", maxWidth: "53px" }}
          >
            <label
              style={{
                backgroundColor: "#f2f2f2",
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
                padding: "10px",
                height: "86px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              PERTO
            </label>
          </div>
          <div
            className="col-lg-11"
            style={{
              paddingLeft: "25px",
              width: "100%",
            }}
          >
            <div className="row">
              <div></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Esférico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeSphereNear}
                  onChange={(evt) =>
                    changePropValue("rightEyeSphereNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Esférico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeSphereNear}
                  onChange={(evt) =>
                    changePropValue("leftEyeSphereNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Cilíndrico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeCylinderNear}
                  onChange={(evt) =>
                    changePropValue("rightEyeCylinderNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Cilíndrico"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeCylinderNear}
                  onChange={(evt) =>
                    changePropValue("leftEyeCylinderNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Eixo"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeAxisNear}
                  onChange={(evt) =>
                    changePropValue("rightEyeAxisNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Eixo"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeAxisNear}
                  onChange={(evt) =>
                    changePropValue("leftEyeAxisNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD DNP"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeDnpNear}
                  onChange={(evt) =>
                    changePropValue("rightEyeDnpNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE DNP"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeDnpNear}
                  onChange={(evt) =>
                    changePropValue("leftEyeDnpNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "2px",
                  width: "100px",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="OD Alt"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.rightEyeAltNear}
                  onChange={(evt) =>
                    changePropValue("rightEyeAltNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="OE Alt"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  className="mt-1"
                  value={opticsData?.leftEyeAltNear}
                  onChange={(evt) =>
                    changePropValue("leftEyeAltNear", evt?.target?.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4" style={{ maxWidth: "250px" }}>
            <FormControlLabel
              label="A.R. NO SPECTRUM"
              id={"idNoSpectrum"}
              control={
                <Checkbox
                  id={"chkNoSpectrum"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      noSpectrum: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.noSpectrum === "y" ? true : false}
                />
              }
              className="mb-1"
            />
            <FormControlLabel
              label="A.R. NO SPECTRUM PODIUM"
              id={"idSpectrumPodium"}
              control={
                <Checkbox
                  id={"chkSpectrumPodium"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      noSpectrumPodium: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.noSpectrumPodium === "y" ? true : false}
                />
              }
            />
            <FormControlLabel
              label="A.R. NO SPECTRUM PODIUM FOG"
              id={"idSpectrumPodiumFog"}
              control={
                <Checkbox
                  id={"chkSpectrumPodiumFog"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      noSpectrumPodiumFog: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={
                    opticsData?.noSpectrumPodiumFog === "y" ? true : false
                  }
                />
              }
            />
            <FormControlLabel
              label="A.R. CRIZAL EASY"
              id={"idCrizalEasy"}
              control={
                <Checkbox
                  id={"chkCrizalEasy"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      crizalEasy: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.crizalEasy === "y" ? true : false}
                />
              }
            />
            <FormControlLabel
              label="A.R. CRIZAL ROCK"
              id={"idCrizalRock"}
              control={
                <Checkbox
                  id={"chkCrizalRock"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      crizalRock: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.crizalRock === "y" ? true : false}
                />
              }
              className="mb-1"
            />
            <FormControlLabel
              label="A.R. OPTIFOG"
              id={"idOptifog"}
              control={
                <Checkbox
                  id={"chkOptifog"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      optifog: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.optifog === "y" ? true : false}
                />
              }
            />
          </div>
          <div className="col-lg-4">
            <FormControlLabel
              label="VIDEO FILTER"
              id={"idVideoFilter"}
              control={
                <Checkbox
                  id={"chkVideoFilter"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      videoFilter: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.videoFilter === "y" ? true : false}
                />
              }
            />

            <FormControlLabel
              label="ULTRA VIOLETA"
              id={"idUltraViolet"}
              control={
                <Checkbox
                  id={"chkUltraViolet"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      ultraViolet: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.ultraViolet === "y" ? true : false}
                />
              }
            />

            <FormControlLabel
              label="ANTI RISCO"
              id={"idAntiRisc"}
              control={
                <Checkbox
                  id={"chkAntiRisc"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      antiRisc: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.antiRisc === "y" ? true : false}
                />
              }
            />

            <div
              className="row"
              style={{ backgroundColor: "green", width: "100%" }}
            ></div>

            <FormControlLabel
              label="COLORAÇÃO"
              id={"idColoring"}
              control={
                <Checkbox
                  id={"chkColoring"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      coloring: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.coloring === "y" ? true : false}
                />
              }
            />

            <FormControlLabel
              label="A.R. NO REFLEX"
              id={"idNoReflex"}
              control={
                <Checkbox
                  id={"chkNoReflex"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      noReflex: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.noReflex === "y" ? true : false}
                />
              }
            />
            <TextField
              label="Outros"
              size="small"
              margin="normal"
              variant="outlined"
              className="mt-1"
              value={opticsData?.others}
              onChange={(evt) => changePropValue("others", evt?.target?.value)}
            />
          </div>
          <div className="col-lg-4">
            <FormControlLabel
              label="NIGHT DRIVE"
              id={"idNightDrive"}
              control={
                <Checkbox
                  id={"chkNightDrive"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      nightDrive: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.nightDrive === "y" ? true : false}
                />
              }
            />

            <FormControlLabel
              label="COATING COM UV"
              id={"idCoatingWithUV"}
              control={
                <Checkbox
                  id={"chkCoatingWithUV"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      coatingWithUV: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.coatingWithUV === "y" ? true : false}
                />
              }
            />

            <FormControlLabel
              label="A.R. CRIZAL PREVENCIA"
              id={"idCrizalPrevencia"}
              control={
                <Checkbox
                  id={"chkCrizalPrevencia"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      crizalPrevencia: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.crizalPrevencia === "y" ? true : false}
                />
              }
            />
            <FormControlLabel
              label="MUDANÇA DE MODELO"
              id={"idModelChange"}
              control={
                <Checkbox
                  id={"chkModelChange"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      modelChange: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.modelChange === "y" ? true : false}
                />
              }
            />
            <FormControlLabel
              label="A.R. CRIZAL SAPPHIRE"
              id={"idCrizalSapphire"}
              control={
                <Checkbox
                  id={"chkCrizalSapphire"}
                  color="primary"
                  onChange={(e) =>
                    setOpticsData((state: TOptics) => ({
                      ...state,
                      crizalSapphire: e?.target?.checked ? "y" : "n",
                    }))
                  }
                  checked={opticsData?.crizalSapphire === "y" ? true : false}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6" style={{ paddingLeft: "25px" }}>
        <div className="row">
          <label>Armação</label>
        </div>
        <div className="row">
          <FormControlLabel
            label="Acetato"
            className="ml-1"
            control={
              <Checkbox
                id={"chkAcetate"}
                color="primary"
                onChange={(e) =>
                  setOpticsData((state: TOptics) => ({
                    ...state,
                    acetate: e?.target?.checked ? "y" : "n",
                  }))
                }
                checked={opticsData?.acetate === "y" ? true : false}
              />
            }
          />
          <FormControlLabel
            label="Metal"
            className="ml-1"
            control={
              <Checkbox
                id={"chkAcetate"}
                color="primary"
                onChange={(e) =>
                  setOpticsData((state: TOptics) => ({
                    ...state,
                    metal: e?.target?.checked ? "y" : "n",
                  }))
                }
                checked={opticsData?.metal === "y" ? true : false}
              />
            }
          />
          <FormControlLabel
            label="Parafuso"
            className="ml-1"
            control={
              <Checkbox
                id={"chkAcetate"}
                color="primary"
                onChange={(e) =>
                  setOpticsData((state: TOptics) => ({
                    ...state,
                    screw: e?.target?.checked ? "y" : "n",
                  }))
                }
                checked={opticsData?.screw === "y" ? true : false}
              />
            }
          />
          <FormControlLabel
            label="Nylon"
            className="ml-1"
            control={
              <Checkbox
                id={"chkAcetate"}
                color="primary"
                onChange={(e) =>
                  setOpticsData((state: TOptics) => ({
                    ...state,
                    nylon: e?.target?.checked ? "y" : "n",
                  }))
                }
                checked={opticsData?.nylon === "y" ? true : false}
              />
            }
          />
        </div>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3px",
            }}
          >
            <TextField
              label="Referência"
              size="small"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("reference", evt?.target?.value)
              }
              value={opticsData?.reference}
            />
            <TextField
              size="small"
              label="Cor"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameColor", evt?.target?.value)
              }
              value={opticsData?.frameColor}
            />
            <TextField
              size="small"
              label="Modelo"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameModel", evt?.target?.value)
              }
              value={opticsData?.frameModel}
            />
          </div>
        </div>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3px",
            }}
          >
            <TextField
              size="small"
              label="Ponte"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameBridge", evt?.target?.value)
              }
              value={opticsData?.frameBridge}
            />
            <TextField
              label="MHA"
              size="small"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameMHA", evt?.target?.value)
              }
              value={opticsData?.frameMHA}
            />
            <TextField
              size="small"
              label="MVA"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameMVA", evt?.target?.value)
              }
              value={opticsData?.frameMVA}
            />
          </div>
        </div>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3px",
            }}
          >
            <TextField
              label="DMA"
              margin="normal"
              variant="outlined"
              size="small"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("frameDMA", evt?.target?.value)
              }
              value={opticsData?.frameDMA}
            />
            <TextField
              size="small"
              label="Diâmetro"
              margin="normal"
              variant="outlined"
              className="mt-1"
              onChange={(evt) =>
                changePropValue("diameter", evt?.target?.value)
              }
              value={opticsData?.diameter}
            />
          </div>
        </div>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3px",
            }}
          >
            <div style={{ width: "300px" }}>
              <TextField
                id="idDoctorName"
                size="small"
                label="Médico"
                margin="normal"
                variant="outlined"
                className="mt-1"
                value={opticsData?.doctorName ?? ""}
                onChange={(evt) =>
                  changePropValue("doctorName", evt?.target?.value)
                }
                disabled={false}
              />
            </div>
            <div style={{ width: "130px" }}>
              <TextField
                size="small"
                type="date"
                margin="normal"
                variant="outlined"
                label="Data Consulta"
                className="mt-1"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) =>
                  setOpticsData((state: TOptics) => ({
                    ...state,
                    medicalAppointmentDate: evt?.target?.value,
                  }))
                }
                value={opticsData?.medicalAppointmentDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
