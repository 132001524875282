import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import ApiResourceSelect from "../../../components/ApiResourceSelect";
import { Carrier } from "../../../types/Carrier";
import CarrierService from "../../../services/CarrierService";
import { useStyles } from "../../../hooks/styles";

interface TransportTabProps {
  modalityFreight: string;
  setModalityFreight: (value: string) => void;
  carrier: string;
  setCarrier: (value: string) => void;
}

const TransportTab: React.FC<TransportTabProps> = ({
  modalityFreight,
  setModalityFreight,
  carrier,
  setCarrier,
}) => {
  const classes = useStyles();

  return (
    <div className="row">
      <div className="col-lg-4">
        <TextField
          size="small"
          select
          label="Modalidade de Frete"
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
          value={modalityFreight}
          onChange={(e) => setModalityFreight(e.target.value)}
        >
          <MenuItem key="0" value="sender">
            Remetente
          </MenuItem>

          <MenuItem key="1" value="recipient">
            Destinatário
          </MenuItem>

          <MenuItem key="5" value="freeShipping">
            Sem Frete
          </MenuItem>
        </TextField>
      </div>

      <div className="col-lg-8">
        <ApiResourceSelect
          label="Transportadora"
          freeSolo
          getOptionLabel={(option: Carrier) => option.name}
          value={carrier}
          onSelect={(option) => setCarrier(option ? String(option.name) : "")}
          onInputChange={(typedText) => setCarrier(typedText || "")}
          apiSearchHandler={(typedText) =>
            CarrierService.getCarriersFiltered({
              name: typedText,
            })
          }
        />
      </div>
    </div>
  );
};

export default TransportTab;
