import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { getDate, getNameMonth } from "../../utils/dateTimeHelper";
import "./Calendar.css";
import api from "../../services/Api";
import { formatCurrency } from "../../utils/formatCurrency";
import { Badge } from "react-bootstrap";
import CashFlowService from "../../services/CashFlowService";
import { BillsCashFlow } from "../../types/BillsCashFlow";

type Calendar = {
  company: string;
  currentCompanyId: number;
  showMonth: string; // "all" || "actual"
  month: number;
  year: number;
  dateSelected: string;
  setDateSelected: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  userHasAccess: boolean;
};

type Bills = {
  id: number;
  typeBills: string;
  amount: number;
  totalPaid: number;
  remaining: number;
  bankAccount: string;
  nameBank: string;
  barCode: string;
  categoryName: string;
  centerCost: string;
  comments: string;
  docNumber: string;
  dueDate: string;
  name: string;
  occurrence: string;
  payment: string;
  supplier: string;
  recordType: string;
  status: string;
  payedDate: string;
};

type Week = {
  dayOfTheWeek: string; // "D" (Domingo) || "S" (Segunda) || "T" (Terça) || "Q" (Quarta) || "Q" (Quinta) || "S" (Sexta) ||
  weekMonth: number;
  date: string;
  day: number;
  receive: Bills[];
  pay: Bills[];
  statusNotifications: any[];
};

type Month = {
  month: number;
  year: number;
  weeks: Week[][];
};

export function Calendar({
  company,
  currentCompanyId,
  showMonth,
  month,
  year,
  dateSelected,
  setDateSelected,
  setMonth,
  setYear,
  userHasAccess,
}: Calendar) {
  const [datesCalendar, setDatesCalendar] = useState<Month[]>([]);
  const today = getDate({ initialDate: new Date() }).dateStr;
  const [realeaseHistory, setRealeaseHistory] = useState<Bills[]>([]);
  const [statusNotificationsHistory, setStatusNotificationsHistory] = useState<
    any[]
  >([]);

  useEffect(() => {
    async function getCalendar() {
      if (showMonth === "actual") {
        var initialMonth = getDate({ initialDate: new Date(year, month - 1) })
          .dateStr;
        var finalMonth = getDate({ initialDate: new Date(year, month, 0) })
          .dateStr;

        await getScheduledObjects(initialMonth, finalMonth).then(
          (scheduledObjects) => {
            const responseCalendar = [
              buildCalendar(initialMonth, finalMonth, scheduledObjects),
            ];

            responseCalendar
              .find((monthCalendar: Month) => monthCalendar.month === month)
              ?.weeks.map(async (weeksMonth: any) => {
                var todayRealeases = weeksMonth.find(
                  (week: any) => week.date === today
                );

                if (todayRealeases) {
                  selectDate(todayRealeases);
                }
              });

            setDatesCalendar(responseCalendar);
          }
        );
      } else {
        var initialMonth = getDate({ initialDate: new Date(year, 0) }).dateStr;
        var finalMonth = getDate({ initialDate: new Date(year, 12, 0) })
          .dateStr;

        var monthsArr: any = [];
        var currentDayLoop = initialMonth;
        await getScheduledObjects(initialMonth, finalMonth).then(
          (scheduledObjects) => {
            do {
              var finalMonthLoop = getDate({
                initialDate: new Date(
                  year,
                  Number(currentDayLoop.split("/")[1]),
                  0
                ),
              }).dateStr;

              monthsArr.push(
                buildCalendar(currentDayLoop, finalMonthLoop, scheduledObjects)
              );

              var monthLoop =
                Number(currentDayLoop.split("/")[1]) + 1 < 10
                  ? "0" + (Number(currentDayLoop.split("/")[1]) + 1)
                  : Number(currentDayLoop.split("/")[1]) + 1;
              currentDayLoop =
                currentDayLoop.split("/")[0] +
                "/" +
                monthLoop +
                "/" +
                currentDayLoop.split("/")[2];
            } while (
              new Date(
                Number(currentDayLoop.split("/")[2]),
                Number(currentDayLoop.split("/")[1]) - 1,
                Number(currentDayLoop.split("/")[0])
              ) <=
              new Date(
                Number(finalMonth.split("/")[2]),
                Number(finalMonth.split("/")[1]) - 1,
                Number(finalMonth.split("/")[0])
              )
            );
          }
        );

        setDatesCalendar(monthsArr);
      }
    }
    getCalendar();
    // selectDate();
  }, [showMonth, month, year, company]);

  function buildCalendar(
    initialMonth: string,
    finalMonth: string,
    scheduledObjects: any
  ): Month {
    var [bills, statusNotifications] = scheduledObjects;

    var allWeeksArr: any = [];
    var currentDayLoop = initialMonth;
    var weekArr = [];
    do {
      var dateWeek = new Date(
        Number(currentDayLoop.split("/")[2]),
        Number(currentDayLoop.split("/")[1]) - 1,
        Number(currentDayLoop.split("/")[0])
      ).getDate();
      var dayWeek = new Date(
        Number(currentDayLoop.split("/")[2]),
        Number(currentDayLoop.split("/")[1]) - 1,
        Number(currentDayLoop.split("/")[0])
      ).getDay();
      var weekMonth = Math.abs(Math.ceil((dateWeek - 1 - dayWeek) / 7));

      var receiveArr = [
        ...bills.filter(
          (receive: BillsCashFlow) =>
            receive.typeBills === "receive" &&
            receive.date ===
              currentDayLoop
                .split("/")
                .reverse()
                .join("-")
        ),
      ];

      var payArr = [
        ...bills.filter(
          (pay: BillsCashFlow) =>
            pay.typeBills === "pay" &&
            pay.date ===
              currentDayLoop
                .split("/")
                .reverse()
                .join("-")
        ),
      ];

      weekArr.push({
        dayOfTheWeek: new Date(
          Number(currentDayLoop.split("/")[2]),
          Number(currentDayLoop.split("/")[1]) - 1,
          Number(currentDayLoop.split("/")[0])
        ).toLocaleDateString("pt-BR", { weekday: "long" }),
        weekMonth: weekMonth,
        date: currentDayLoop,
        day: Number(currentDayLoop.split("/")[0]),
        receive: userHasAccess ? receiveArr : [],
        pay: userHasAccess ? payArr : [],
        statusNotifications: statusNotifications.filter(
          (notification: any) =>
            notification.scheduleDate ===
            currentDayLoop
              .split("/")
              .reverse()
              .join("-")
        ),
      });

      var day =
        Number(currentDayLoop.split("/")[0]) + 1 < 10
          ? "0" + (Number(currentDayLoop.split("/")[0]) + 1)
          : Number(currentDayLoop.split("/")[0]) + 1;
      currentDayLoop =
        day +
        "/" +
        currentDayLoop.split("/")[1] +
        "/" +
        currentDayLoop.split("/")[2];

      var nextDateWeek = new Date(
        Number(currentDayLoop.split("/")[2]),
        Number(currentDayLoop.split("/")[1]) - 1,
        Number(currentDayLoop.split("/")[0])
      ).getDate();
      var nextDayWeek = new Date(
        Number(currentDayLoop.split("/")[2]),
        Number(currentDayLoop.split("/")[1]) - 1,
        Number(currentDayLoop.split("/")[0])
      ).getDay();
      var nextWeekMonth = Math.abs(
        Math.ceil((nextDateWeek - 1 - nextDayWeek) / 7)
      );

      if (nextWeekMonth != weekMonth) {
        var sunday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "domingo"
        );
        var monday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "segunda-feira"
        );
        var tuesday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "terça-feira"
        );
        var wednesday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "quarta-feira"
        );
        var thursday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "quinta-feira"
        );
        var friday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "sexta-feira"
        );
        var saturday = weekArr.find(
          (week: any) => week.dayOfTheWeek === "sábado"
        );

        if (!sunday) {
          sunday = {
            dayOfTheWeek: "domingo",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!monday) {
          monday = {
            dayOfTheWeek: "segunda-feira",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!tuesday) {
          tuesday = {
            dayOfTheWeek: "terça-feira",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!wednesday) {
          wednesday = {
            dayOfTheWeek: "quarta-feira",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!thursday) {
          thursday = {
            dayOfTheWeek: "quinta-feira",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!friday) {
          friday = {
            dayOfTheWeek: "sexta-feira",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        if (!saturday) {
          saturday = {
            dayOfTheWeek: "sábado",
            weekMonth: weekMonth,
            date: "",
            day: 0,
            receive: [],
            pay: [],
            statusNotifications: [],
          };
        }

        allWeeksArr.push([
          { ...sunday },
          { ...monday },
          { ...tuesday },
          { ...wednesday },
          { ...thursday },
          { ...friday },
          { ...saturday },
        ]);
        weekArr = [];
      }
    } while (
      new Date(
        Number(currentDayLoop.split("/")[2]),
        Number(currentDayLoop.split("/")[1]) - 1,
        Number(currentDayLoop.split("/")[0])
      ) <=
      new Date(
        Number(finalMonth.split("/")[2]),
        Number(finalMonth.split("/")[1]) - 1,
        Number(finalMonth.split("/")[0])
      )
    );

    var datesCalendarArr = {
      year: Number(initialMonth.split("/")[2]),
      month: Number(initialMonth.split("/")[1]),
      weeks: allWeeksArr,
    };

    return datesCalendarArr;
  }

  async function getScheduledObjects(initialDate: string, finalDate: string) {
    var initialDate = getDate({
      initialDate: new Date(
        Number(initialDate.split("/")[2]),
        Number(initialDate.split("/")[1]) - 1,
        Number(initialDate.split("/")[0])
      ),
    }).dateStr;
    var finalDate = getDate({
      initialDate: new Date(
        Number(finalDate.split("/")[2]),
        Number(finalDate.split("/")[1]) - 1,
        Number(finalDate.split("/")[0])
      ),
    }).dateStr;

    var bills = await CashFlowService.getBills({
      accountBank: "",
      initialDate: initialDate
        .split("/")
        .reverse()
        .join("-"),
      finalDate: finalDate
        .split("/")
        .reverse()
        .join("-"),
      foreseen: true,
      accomplished: true,
      company: company.length ? String(company) : String(currentCompanyId),
    });

    var statusNotifications = await api.get("notifications/schedule", {
      params: {
        foreseen: true,
        accomplished: true,
        initialDate: initialDate
          .split("/")
          .reverse()
          .join("-"),
        finalDate: finalDate
          .split("/")
          .reverse()
          .join("-"),
        company: company.length ? company : currentCompanyId,
      },
    });

    return [bills, statusNotifications.data];
  }

  function changeMonth(typeChange: string) {
    var monthChanged = month;
    var yearChanged = year;

    if (typeChange === "prev") {
      monthChanged = monthChanged - 1 === 0 ? 12 : monthChanged - 1;
      yearChanged = monthChanged < 1 ? yearChanged-- : yearChanged;
    } else {
      monthChanged = monthChanged + 1 === 13 ? 1 : monthChanged + 1;
      yearChanged = monthChanged > 12 ? yearChanged++ : yearChanged;
    }

    setMonth(monthChanged);
    setYear(yearChanged);
  }

  function selectDate(date: Week) {
    var pay = date.pay;
    var receive = date.receive;
    var statusNotifications = date.statusNotifications;

    for (var indexToPay = 0; indexToPay < pay.length; indexToPay++) {
      pay[indexToPay].typeBills = "pay";
    }

    for (
      var indexToReceive = 0;
      indexToReceive < receive.length;
      indexToReceive++
    ) {
      receive[indexToReceive].typeBills = "receive";
    }

    var realeaseHistoryArr = [...pay, ...receive];

    realeaseHistoryArr.sort(function(element1: any, element2: any) {
      var amount1: any = new Date(element1.amount);
      var amount2: any = new Date(element2.amount);

      return amount2 - amount1;
    });

    setRealeaseHistory(realeaseHistoryArr);
    setStatusNotificationsHistory(statusNotifications);
    setDateSelected(date.date);
  }

  const quadReceive = {
    width: "14px",
    height: "10px",
    marginRight: "5px",
    backgroundColor: "green",
  };

  const quadPay = {
    width: "14px",
    height: "10px",
    marginRight: "5px",
    backgroundColor: "red",
  };

  const quadStatus = {
    width: "14px",
    height: "10px",
    marginRight: "5px",
    backgroundColor: "blue",
  };

  return (
    <div className="row col-12 d-flex flex-row">
      <div className="col-lg-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between border-bottom pb-2">
            {showMonth === "all" && month - 1 > 0 ? (
              <button
                className="btn btn-sm btn-outline-secondary d-flex justify-content-center align-items-center"
                style={{ padding: "5px" }}
                onClick={() => changeMonth("prev")}
              >
                <i className="flaticon2-left-arrow"></i>
              </button>
            ) : (
              <p></p>
            )}

            <h5>
              {getNameMonth(month < 10 ? "0" + month : String(month), "name")}{" "}
              {year}
            </h5>

            {showMonth === "all" && month + 1 < 13 ? (
              <button
                className="btn btn-sm btn-outline-secondary d-flex justify-content-center align-items-center"
                style={{ padding: "5px" }}
                onClick={() => changeMonth("next")}
              >
                <i className="flaticon2-right-arrow"></i>
              </button>
            ) : (
              <p></p>
            )}
          </div>

          <div className="col-12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="cell">
                    <button className="headWeek">D</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">S</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">T</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">Q</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">Q</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">S</button>
                  </TableCell>
                  <TableCell className="cell">
                    <button className="headWeek">S</button>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {datesCalendar
                  .find((monthCalendar: Month) => monthCalendar.month === month)
                  ?.weeks.map((allWeek: any, indexWeek: number) => {
                    return (
                      <TableRow key={indexWeek} className="bodyRow">
                        {allWeek.map((week: any, index: number) => {
                          return (
                            <TableCell
                              key={week.weekMonth + "-" + index}
                              id={week.date === today ? "btnToday" : ""}
                              className="bodyCell cell"
                            >
                              {week.day > 0 ? (
                                <button
                                  id={
                                    week.date === dateSelected
                                      ? "dateSelected"
                                      : ""
                                  }
                                  className="btnDay d-flex flex-column justify-content-center"
                                  onClick={() => selectDate(week)}
                                >
                                  {week.day}
                                  <div className="d-flex flex-row align-items-center justify-content-between">
                                    {week.receive.length ? (
                                      <div className="badgeReceive"></div>
                                    ) : (
                                      <></>
                                    )}
                                    {week.pay.length ? (
                                      <div className="badgePay"></div>
                                    ) : (
                                      <></>
                                    )}
                                    {week.statusNotifications.length ? (
                                      <div className="badgeStatus"></div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </button>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            <div className="d-flex justify-content-center align-items-center">
              <p
                className="m-3 d-flex flex-row align-items-center"
                style={{ color: "green" }}
              >
                <span style={quadReceive}></span>
                Receita
              </p>
              <p
                className="m-3 d-flex flex-row align-items-center"
                style={{ color: "red" }}
              >
                <span style={quadPay}></span>
                Despesa
              </p>
              <p
                className="m-3 d-flex flex-row align-items-center"
                style={{ color: "blue" }}
              >
                <span style={quadStatus}></span>
                Notificação de Status
              </p>
            </div>
          </div>
        </div>
      </div>

      {realeaseHistory.length || statusNotificationsHistory.length ? (
        <div
          id="realeaseHistory"
          className="col-lg-7 d-flex flex-column border p-3"
        >
          {realeaseHistory.map((realease: Bills) => {
            return (
              <div
                key={`${realease.typeBills}-${realease.id}`}
                className="d-flex flex-row justify-content-between align-items-center border-bottom mb-3"
              >
                <div className="d-flex flex-column mb-3">
                  <h5>{realease.name}</h5>
                  <b
                    style={
                      realease.typeBills === "receive"
                        ? { color: "green" }
                        : { color: "red" }
                    }
                    className="d-flex flex-row align-items-center"
                  >
                    {realease.status === "paid"
                      ? formatCurrency(realease.totalPaid)
                      : formatCurrency(realease.amount)}

                    {realease.status === "paid" ? (
                      <Badge
                        pill
                        variant="primary"
                        className="ml-3 d-flex align-items-center"
                        style={
                          realease.typeBills === "receive"
                            ? { color: "#fff", background: "green" }
                            : { color: "#fff", background: "red" }
                        }
                      >
                        <i
                          className="flaticon2-check-mark mr-2"
                          style={{ color: "#fff" }}
                        ></i>
                        {realease.typeBills === "receive" ? "Recebido" : "Pago"}
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </b>
                </div>
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Visualizar Lançamento"
                >
                  <a
                    href={
                      realease.typeBills === "receive"
                        ? `/contas-a-receber/${realease.id}`
                        : `/contas-a-pagar/${realease.id}`
                    }
                    className="btn btn-sm btn-outline-secondary"
                  >
                    <i className="p-0 flaticon-eye"></i>
                  </a>
                </Tooltip>
              </div>
            );
          })}
          {statusNotificationsHistory.map((notification: any) => (
            <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-3">
              <div className="d-flex flex-column mb-3">
                <h5>{notification.title}</h5>
                <b
                  style={{ color: "blue" }}
                  className="d-flex flex-row align-items-center"
                >
                  {notification.message}
                </b>
              </div>
              <Tooltip TransitionComponent={Zoom} title="Visualizar">
                <a
                  href={notification.link}
                  className="btn btn-sm btn-outline-secondary"
                >
                  <i className="p-0 flaticon-eye"></i>
                </a>
              </Tooltip>
            </div>
          ))}
        </div>
      ) : (
        <div
          id="realeaseHistory"
          className="col-lg-7 d-flex flex-column border p-3"
        >
          <h5 style={{ color: "#ccc" }}>
            Nenhum lançamento ou evento para este dia...
          </h5>
        </div>
      )}
    </div>
  );
}
