import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { TextField, MenuItem, InputAdornment } from "@material-ui/core";

import { PaymentDetails, usePurchaseOrder } from "./context";
import { paymentOptions } from "../../utils/paymentOptions";
import { NumericFormat } from "../../components/NumericFormat";

import "../../style.css";
import { formatToFloat } from "../../utils/formatCurrency";
import { useSelector } from "react-redux";

interface ConditionOrInstallmentProps {
  total: number;
  conditionName: string;
  setConditionName: Dispatch<SetStateAction<string>>;
  status?: string;
}

export const ConditionOrInstallment: React.FC<ConditionOrInstallmentProps> = ({
  total,
  conditionName,
  setConditionName,
  status,
}) => {
  const { user } = useSelector((state: any) => state.auth);

  const [installmentsValue, setInstallmentsValue] = useState(1);
  const { classes, dispatchPayment, payments } = usePurchaseOrder();

  useEffect(() => {
    setInstallmentsValue(payments.length);
  }, [payments]);

  function handleInstallmentDateChange(
    date: string,
    index: number,
    payment: PaymentDetails
  ) {
    dispatchPayment({
      type: "CHANGE_DATE",
      payload: {
        index,
        date,
        payment: {
          ...payment,
        },
      },
    });
  }

  return (
    <div className="row">
      {/* <div className="col-lg-3 d-flex align-items-center">
                <Autocomplete
                    style={{ width: "100%" }}
                    options={conditionsNames}
        
                    value={conditionName}
                    onChange={(_, value) => value && setConditionName(value)}
                    renderInput={(params) => (
                        <TextField
                            { ...params }
                            size="small"
                            label="Condição de pagamento"
                            margin="normal"
                            variant="outlined"
                            disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                        />
                    )}
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="p-3 d-flex align-items-center justify-content-center">
                <label>ou</label>
            </div> */}

      <div className="col-lg-3 d-flex align-items-center">
        <TextField
          label="Quantidade de Parcelas*"
          margin="normal"
          variant="outlined"
          size="small"
          value={installmentsValue}
          onChange={(evt) => setInstallmentsValue(Number(evt.target.value))}
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        />
      </div>

      <div className="col-md-3 d-flex align-items-center justify-content-center">
        <Button
          variant="primary"
          size="sm"
          onClick={() =>
            dispatchPayment({
              type: "GENERATE",
              payload: {
                amount: installmentsValue,
                value: total,
              },
            })
          }
          block
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        >
          Gerar parcelas
        </Button>
      </div>

      {payments.map((payment, index) => (
        <div className="row col-lg-12 ml-2">
          <div className="col-lg-3 d-flex align-items-center p-1">
            <div className="d-flex justify-content-center align-items-center bg-primary p-3 mr-2 mt-2">
              <label className="text-white m-0">{index + 1}.</label>
            </div>

            <TextField
              size="small"
              type="date"
              label="Data"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={payment.date}
              onChange={(event) =>
                handleInstallmentDateChange(event.target.value, index, payment)
              }
              disabled={
                status == "attended" || user.isAccountant == "y" ? true : false
              }
            />
          </div>

          <div className="col p-1">
            {index + 1 !== payments.length ? (
              <NumericFormat
                key={index}
                label="Valor"
                value={payment.value}
                className="inputSmaller"
                withPrefix={false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                onChange={({ target: { value } }) =>
                  dispatchPayment({
                    type: "CHANGE_VALUE",
                    payload: {
                      index,
                      value: formatToFloat(value),
                      amount: installmentsValue,
                      payment: {
                        ...payment,
                        value: formatToFloat(value),
                      },
                    },
                  })
                }
                disabled={
                  status == "attended" || user.isAccountant == "y"
                    ? true
                    : false
                }
              />
            ) : (
              <NumericFormat
                disabled
                key={index}
                label="Valor"
                value={payment.value}
                className="inputSmaller"
                withPrefix={false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            )}
          </div>

          <div className="col p-1">
            <TextField
              select
              size="small"
              label="Forma de pgto"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
              value={payment.payment}
              onChange={(evt) =>
                dispatchPayment({
                  type: "CHANGE",
                  payload: {
                    index,
                    payment: {
                      ...payment,
                      payment: evt.target.value,
                    },
                  },
                })
              }
              disabled={
                status == "attended" || user.isAccountant == "y" ? true : false
              }
            >
              <MenuItem key="0" value="0">
                Selecione
              </MenuItem>

              {paymentOptions.map((payment, index) => (
                <MenuItem key={index} value={payment.value}>
                  {payment.value}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col p-1">
            <TextField
              size="small"
              label="Observações"
              margin="normal"
              variant="outlined"
              value={payment.comments}
              onChange={(evt) =>
                dispatchPayment({
                  type: "CHANGE",
                  payload: {
                    index,
                    payment: {
                      ...payment,
                      comments: evt.target.value,
                    },
                  },
                })
              }
              disabled={
                status == "attended" || user.isAccountant == "y" ? true : false
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};
