import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { ApiResourceSelectAddButton } from "./ApiResourceSelectAddButton";
import { situation } from "../types/Seller";

type Option = any | "__add__";

type ProductSelectProps = {
  label: string;
  isEdit?: boolean;
  selectedProduct: any;
  onChangeProduct: (product: any) => void;
  entityType?: string;
  entityId?: number | string;
  disabled?: boolean;
  hasError?: boolean;
  type?: "product" | "service";
  allowIncomplete?: boolean;
  onClickAddProduct?: (typedText: string) => void;
};

export default function ProductSelect({
  label,
  isEdit,
  selectedProduct,
  onChangeProduct,
  entityType,
  entityId,
  disabled,
  hasError,
  type,
  allowIncomplete = false,
  onClickAddProduct,
}: ProductSelectProps) {
  const [foundProducts, setFoundProducts] = useState<Option[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    async function handleSelectedProduct() {
      if (selectedProduct) {
        const productLoaded = foundProducts.find(
          (product) =>
            product !== "__add__" && product.id === selectedProduct.INSTANCE?.id
        );
        if (!productLoaded) {
          const product = await ProductService.getProductById(
            selectedProduct.INSTANCE?.id
          );
          if (product) {
            setFoundProducts((prevState) => [...prevState, product]);
          }
        }
      }
    }
    handleSelectedProduct();
  }, [selectedProduct]);

  const handleChangeProductId = useCallback(
    (value: any) => {
      if (typeof value === "object") {
        onChangeProduct(value);
      }
    },
    [onChangeProduct]
  );

  const handleChangeProductName = useCallback(
    (value: any, reason: AutocompleteInputChangeReason) => {
      if (reason === "reset") {
        return;
      }
      setFoundProducts([]);

      if (value.length >= 1) {
        setIsSearching(true);

        const findPromiseHandler = (foundProducts: any[]) => {
          const optionsArray: Option[] = foundProducts;

          if (onClickAddProduct) {
            optionsArray.push("__add__");
          }

          setIsSearching(false);
          setFoundProducts(optionsArray);
        };

        if (!type || type === "product") {
          ProductService.getProductsAndVariationsFiltered(
            { name: value, situation: situation.ACTIVE },
            !type
          ).then(findPromiseHandler);
        } else {
          ProductService.getServicesFiltered({ name: value }).then(
            findPromiseHandler
          );
        }
      }

      if (allowIncomplete) {
        onChangeProduct(value);
      }
    },
    [onChangeProduct]
  );

  function handleGetOptionLabel(option: Option | string) {
    if (option === "__add__") {
      return "";
    }

    if (typeof option === "string") {
      return "";
    }

    return option?.name ?? option?.INSTANCE?.name ?? "";
  }

  function handleRenderOption(option: Option | string, typedText: string) {
    if (option === "__add__" && onClickAddProduct) {
      return (
        <ApiResourceSelectAddButton
          label={`Adicionar ${label.replace(" *", "").replace("*", "")}`}
          onClick={() => onClickAddProduct(typedText)}
        />
      );
    }

    return <>{handleGetOptionLabel(option)}</>;
  }

  return (
    <Autocomplete
      filterOptions={(options, state) => options}
      size="small"
      loading={isSearching}
      loadingText="Carregando..."
      freeSolo={allowIncomplete}
      style={{ width: "100%" }}
      noOptionsText="Sem opções"
      options={foundProducts}
      getOptionLabel={handleGetOptionLabel}
      value={selectedProduct}
      onChange={(evt, value) => handleChangeProductId(value)}
      onInputChange={(evt, value, reason) =>
        handleChangeProductName(value, reason)
      }
      disabled={disabled}
      renderOption={(option, state) =>
        handleRenderOption(option, state.inputValue)
      }
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          label={label}
          margin="normal"
          variant="outlined"
          error={hasError}
          InputProps={{
            ...params.InputProps,
            startAdornment:
              allowIncomplete &&
              selectedProduct.name &&
              !selectedProduct.isLinked ? (
                <InputAdornment position="end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {label} sem cadastro vinculado clique para realizar o
                        cadastro
                      </Tooltip>
                    }
                  >
                    {onClickAddProduct ? (
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onClickAddProduct(String(selectedProduct.name))
                        }
                      >
                        <Badge pill variant="warning" className="text-light">
                          <i
                            className="flaticon2-warning"
                            style={{ color: "#fff" }}
                          ></i>
                        </Badge>
                      </div>
                    ) : (
                      <Badge pill variant="warning" className="text-light">
                        <i
                          className="flaticon2-warning"
                          style={{ color: "#fff" }}
                        ></i>
                      </Badge>
                    )}
                  </OverlayTrigger>
                </InputAdornment>
              ) : (
                params.InputProps.startAdornment
              ),
          }}
        />
      )}
    />
  );
}
