import { useEffect, useState } from "react";
import openSocket, { io, Socket } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_API_URL || "";

export function socketConnection(params?: any) {
  return openSocket(SOCKET_URL.replace("api/", "").replace("api", ""), {
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: params ? { ...params } : undefined,
  } as any);
}

export const useWebSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [invoice, setInvoice] = useState({ status: "", invoiceId: "" });

  const handleConnect = () => {
    const newSocket = socketConnection();
    setSocket(newSocket);

    newSocket.on("response", (data: any) => {
      setMessages((prev) => [...prev, data]);
    });

    newSocket.on("broadcast", (data: any) => {
      setInvoice(data);
    });

    return () => {
      newSocket.disconnect();
    };
  };

  useEffect(() => {
    handleConnect();
  }, []);

  const sendMessage = (message: string) => {
    if (!socket) socketConnection();
    if (socket) {
      socket.emit("message", message);
    }
  };

  return { messages, sendMessage, invoice };
};
