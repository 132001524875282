import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Modal,
  IconButton,
} from "@material-ui/core";
import apiBFF from "../../services/ApiBFF";
import { useWebSocket } from "../../hooks/useWebSocket";

interface IVideo {
  video: string;
  title: string;
  description: string;
}

const useStyles = makeStyles((theme) => ({
  mainPaperContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
  },
  mainPaper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  helpPaper: {
    position: "relative",
    width: "100%",
    minHeight: "340px",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "340px",
  },
  paperHover: {
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: `0 0 8px`,
      color: theme.palette.primary.main,
    },
  },
  videoThumbnail: {
    width: "100%",
    height: "calc(100% - 56px)",
    objectFit: "cover",
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
  },
  videoTitle: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  videoDescription: {
    maxHeight: "100px",
    overflow: "hidden",
  },
  videoModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoModalContent: {
    outline: "none",
    width: "90%",
    maxWidth: 1024,
    aspectRatio: "16/9",
    position: "relative",
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
}));

export function ListHelp() {
  const classes = useStyles();
  const [records, setRecords] = useState<IVideo[]>([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await apiBFF.get("helps");
      setRecords(response.data);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVideoModal = (video: any) => {
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  const handleModalClose = useCallback((event) => {
    if (event.key === "Escape") {
      closeVideoModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleModalClose);
    return () => {
      document.removeEventListener("keydown", handleModalClose);
    };
  }, [handleModalClose]);

  const renderVideoModal = () => {
    return (
      <Modal
        open={Boolean(selectedVideo)}
        onClose={closeVideoModal}
        className={classes.videoModal}
      >
        <div className={classes.videoModalContent}>
          {selectedVideo && (
            <iframe
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              src={`https://www.youtube.com/embed/${selectedVideo}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    );
  };

  const renderHelps = () => {
    return (
      <>
        <div className={`${classes.mainPaper} ${classes.mainPaperContainer}`}>
          {records.length
            ? records.map((record, key) => (
                <Paper
                  key={key}
                  className={`${classes.helpPaper} ${classes.paperHover}`}
                  onClick={() => openVideoModal(record.video)}
                >
                  <img
                    src={`https://img.youtube.com/vi/${record.video}/mqdefault.jpg`}
                    alt="Thumbnail"
                    className={classes.videoThumbnail}
                  />
                  <Typography variant="button" className={classes.videoTitle}>
                    {record.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.videoDescription}
                  >
                    {record.description}
                  </Typography>
                </Paper>
              ))
            : null}
        </div>
      </>
    );
  };

  return (
    <div>
      <div style={{ flex: 1, padding: "10px", height: `calc(100% - 48px)` }}>
        <div
          style={{
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 6px 6px 6px",
            }}
          >
            <Typography variant="h5" gutterBottom>
              <span style={{ color: "white" }}> Central de ajuda</span>
            </Typography>
            <div
              style={{
                flex: "none",
                marginLeft: "auto",
              }}
            ></div>
          </div>
          <div className={classes.mainPaper}>{renderHelps()}</div>
          {renderVideoModal()}
        </div>
      </div>
    </div>
  );
}
